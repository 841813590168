import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-datatable-search',
  templateUrl: './datatable-search.component.html',
  styleUrls: ['./datatable-search.component.scss']
})
export class DatatableSearchComponent implements OnInit, OnChanges {

  input_search: FormControl = new FormControl();
  @Input() placeholder_text: string;
  @Input() unique_id =  '';
  @Output() form_search_event: EventEmitter<any> = new EventEmitter<any>();
  @Input() default_value?: string = '';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.default_value) {
      this.input_search.setValue(changes.default_value.currentValue)
    }
  }

  search_dt_data(){
    if (this.input_search.value != '' && this.input_search.value != null) {
      this.form_search_event.emit({'type': 'search','data': this.input_search.value });
    }
  }

  clear_event(event){
    if (this.input_search.value == '' || this.input_search.value == null) {
      this.form_search_event.emit({'type': 'clear','data': event.target.value });
    }
  }

  input_search_change(event){
    if (event.keyCode != '13') {
      if(this.input_search.value != '' && this.input_search.value != null){
        this.form_search_event.emit({'type': 'change','data': event.target.value });
      }else{
        this.clear_event(event);
      }
    }
  }

  reset_search() {
    this.input_search.reset();
  }

}
