import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, SimpleChanges, OnChanges } from '@angular/core';

interface editEvent {
  'case': 'edit' | 'cancel-edit' | 'update' | 'complete';
  'payload': object;
  'editing': boolean;
}
@Component({
  selector: 'app-cancel-edit',
  templateUrl: './cancel-edit.component.html',
  styleUrls: ['./cancel-edit.component.scss']
})
export class CancelEditComponent implements OnInit, OnChanges {

  @Input() payload_dict?: any = {};
  @Input() show_edit_button: boolean;
  @Input() class_:string = "btn btn-primary btn-sm mr-2";
  @Output() edit_event: EventEmitter<editEvent> = new EventEmitter<editEvent>();
  @Input() update_button_data: any = {
    'update_button_id': '',
    'update_service_url': '',
  }
  @Input() generic_button: boolean = false;

  previous_value: any = {};
  btn_loader: boolean = false;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.show_edit_button && !changes.show_edit_button.firstChange) {
      
    }
  }

  click_event(case_) {
    let data: editEvent = {
      'case': case_,
      'payload': {},
      'editing': this.show_edit_button,
    }
    switch (case_) {
      case "edit":
        for (var i in this.payload_dict) {
          if (this.payload_dict[i]) {
            if (typeof (this.payload_dict[i].getRawValue) === 'function') {
              this.previous_value[i] = JSON.parse(JSON.stringify(this.payload_dict[i].getRawValue()));
            } else {
              this.previous_value[i] = JSON.parse(JSON.stringify(this.payload_dict[i]))
            }
          } else {
            this.previous_value[i] = this.payload_dict[i]
          }
        }
        this.edit_event.emit(data);
        break;
      case "cancel-edit":
        data['payload'] = this.previous_value
        this.edit_event.emit(data);
        break;
      case "validate":
        this.edit_event.emit(data);
      break;
      case "update":
        this.update_service_call();
      break;
    }

  }

  update_service_call() {
    if(this.update_button_data.update_service_url == '') {
      console.log('%c Please Enter Service URL', 'color: #FF3131');
      return;
    }
    this.btn_loader = true;
    const service = new UpdateService(this.http);
    try {
      service.get_service_data(this.update_button_data.update_service_url, this.payload_dict).subscribe(res => {
        this.btn_loader = false;
        this.edit_event.emit({
          'case': 'complete',
          'payload': res,
          'editing': this.show_edit_button
        });
      })
    } catch(err) {
      console.log(err);
    }
  }

}

export class UpdateService {

  constructor(
    private http: HttpClient
  ) { }

  get_service_data(service_url, payload) {
    return this.http.post<any>(service_url, payload)
  }
}
