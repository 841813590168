import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from 'src/app/dce-service/navigation.service';
// import { SearchService } from 'src/app/dce-service/search.service';
//import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
//import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
//import { SelectCustomerComponent } from 'src/app/dce-shared/select-customer/select-customer.component';
import { SharedService } from 'src/app/dce-service/shared.service';

@Component({
  selector: 'app-admin-layout-sidebar-large',
  templateUrl: './admin-layout-sidebar-large.component.html',
  styleUrls: ['./admin-layout-sidebar-large.component.scss']
})
export class AdminLayoutSidebarLargeComponent implements OnInit {
  //@ViewChild(SelectCustomerComponent, { static: false }) cusComp: SelectCustomerComponent;
    moduleLoading: boolean;
    customer_number = null;

    //@ViewChild(PerfectScrollbarDirective, { static: true }) perfectScrollbar: PerfectScrollbarDirective;
  
    constructor(
      public navService: NavigationService,
      public _shared:SharedService,
      // public searchService: SearchService,
      private router: Router
    ) { }
  
    ngOnInit() {
      this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
          this.moduleLoading = true;
        }
        if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
          this.moduleLoading = false;
        }
      });
    }

    customer_event(e){
      const literal = {
        cus_select: () => {

        },
      }
      if (literal[e.case]) { //fail-safe
        return literal[e.case](this);
      }
    }

}
