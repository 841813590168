import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { CommonService } from './common.service';
import { EnvService } from './env.service';
import { web_page_title } from './env.service';
import { ProjectSharedService } from './project-shared.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { check_internet } from './check_internet';

// sip_store
type sip_statuses = null | 'pre-login' | 'logged-in' | 'call-fire' | 'call-incoming' | 'call-outgoing' | 'call-on' | 'call-ended';
type call_module = null | 'telephony' | 'campaign';
interface sip_store {
  // DCE  --  sip-render
  lifecycle_status: sip_statuses,
  cus_info_toggle: boolean,
  call_log_table_id: number,  //  unused
  call_hold_id: number,       //  unused
  all_telephone_user: Array<object>,  //  unused
  is_net_on: boolean,         //  unused
  telephony_keys: any,        // will be of default workgrp's telephony details keys
  config_data: any,
  safe_logout: boolean,
  call_trigger_observer: any, // behaviour subject will trigger on call_placement
  call_module: call_module,
  sip_login: boolean,
  qm_login:boolean,
  is_loading: boolean,
  is_operation_outbound:boolean
  
  current_pause_reason: string,
  previous_pause_reason: string,
  dispositons_codes_list: any,
  telephone_log2target: string, // campaign target internal id
  
  // QM  --  sip-render
  qm_call_id: string,
  agent_queues: string[],
  available_pause_codes: Array<{ pause_reason: null, pause_code: null }>,
  current_pause_code: string,
  previous_pause_code: string,
  disposition_dict: any, //{value_value, value_name}
  

  // SIP  --  sip-wrapper
  uri: string,
  password: string,
  authorization_user: string,
  sip_ext: number,
  call_id: string,
  incoming_number: string,    //  served inbound and outbound
  
}

interface Tenant {
  tenant_id: number;
  default_tenant_id: number;
  tenants_list: Array<any>;
  tenantbizorg: Array<number>;
}

declare var $: any;
declare var navigator: any;

@Injectable({
  providedIn: 'root'
})
export class SharedService extends ProjectSharedService{

  isLoading = new BehaviorSubject(false);
  roles_map = {};
  public resetCache = false
  values_data: any = {};
  sip_storage: sip_store;
  auto_populate_keywords = null;
  tags_list:any = {
    'external_reference_number_task':'',
    'campaign_version_tags':''
  };
  task_approval_fields = null;
  campaign_conf = {
    campaign_mandatory_fields : null,
    content_keys: null,
    campaign_version_target_status: null
  }

  tenant_obj: Tenant = {
    tenant_id: null,
    default_tenant_id: null,
    tenants_list: [],
    tenantbizorg: []
  }

  selected_tenent_id = -1

  constructor(
    private _commonService: CommonService,
    private _env: EnvService,
    private _router: Router,
    private http: HttpClient,
    private check_internet: check_internet,
  ) {
    super();
    document.title = web_page_title;
    this.initialize_sip_interface();
  }

  initialize_sip_interface() {
    this.sip_storage = {
      lifecycle_status: 'pre-login',
      uri: null,
      password: null,
      authorization_user: null,
      sip_ext: null,
      call_id: null,
      incoming_number: null,
      call_log_table_id: null,
      call_hold_id: null,
      cus_info_toggle: false,
      is_operation_outbound:false,
      agent_queues: [],
      available_pause_codes: [],
      current_pause_code: null,
      previous_pause_code:null,
      current_pause_reason: null,
      previous_pause_reason: null,
      all_telephone_user: [],
      is_net_on: true,
      telephony_keys: null,
      config_data: null,
      dispositons_codes_list: null,
      disposition_dict: null,
      safe_logout: false,
      call_trigger_observer:new BehaviorSubject(null),
      call_module: null,
      sip_login: false,
      qm_login:false,
      is_loading: false,
      qm_call_id: null,
      telephone_log2target: null
    };
    this.check_internet.sip_is_net_on.subscribe(res => { this.sip_storage.is_net_on = res; });
  }

  get_classifications() {
    return this.http.get<any>(`${this._env.service_url}/data/classifications`);
  }

  // method to show/hide spinner
  spinnerVisibility(is_visible) {
    this.isLoading.next(is_visible);
  }

  async get_data_after_refresh() {
    //   Needs to be implemented once API given
    // const wf = await this._commonService.get_data_after_refresh({}).toPromise();
    // this.setValue('internal_biz_id', wf.internal_biz_id);
    // this.setValue('user_details', wf.user_details);
    // this.setValue('internal_biz_name', wf.internal_biz_name);
    // this.setValue('user_id', wf.user_details.user_id);
    // this.setValue('current_role', wf.current_role);
    // this.setValue('user_roles', wf.user_roles);
  }

  async get_all_telephony_users() {
    const payload = {};
    const res = await this._commonService.get_all_telephony_users(payload).toPromise();
    if (res.errCode === 0) {
      this.serialize_telepohny_users(res.data);
    }
  }

  async get_user_default_workgroup() {
    const payload = { "wrkgrp_type": "Case" };
    const res = await this._commonService.get_user_default_workgroup(payload).toPromise();
    if (res.errCode === 0) {
      this.sip_storage.telephony_keys = res.msg.length ? res.msg[0].telephony_details : null;
    }
  }

  async get_config_data_by_name() {
    const payload = { "conf_names": ["telephony", "auto_populate_keywords", "tags_list", "task_approval_fields", "dispositions_codes_telephony", "campaign_mandatory_fields", "campaign_conf",'campaign_version_target_status'] };
    // const res = await this.lg_config_service.get_config_data_by_name(payload).toPromise();
    // if (res.errCode === 0) {
    //   if (res.msg.telephony) {
    //     this.sip_storage.config_data = res.msg.telephony;
    //     this.check_internet.jssip_net_ping_timer = this.sip_storage.config_data.jssip_net_ping_timer;
    //   }
    //   this.auto_populate_keywords = res.msg.auto_populate_keywords ? res.msg.auto_populate_keywords : null;
    //   this.sip_storage.dispositons_codes_list = res.msg.dispositions_codes_telephony ? res.msg.dispositions_codes_telephony : null;
    //   if (res.msg.tags_list) {
    //     this.tags_list = res.msg.tags_list;
    //   }
    //   if (res.msg.task_approval_fields) {
    //     this.task_approval_fields = res.msg.task_approval_fields;
    //   }
    //   if (res.msg.campaign_mandatory_fields) {
    //     this.campaign_conf.campaign_mandatory_fields = res.msg.campaign_mandatory_fields; // db-conf item('campaign_mandatory_fields') can be placed inside campaign_conf
    //   }
    //   if (res.msg.campaign_conf) {
    //     this.campaign_conf.content_keys = res.msg.campaign_conf.content_keys;
    //   }
    //   if(res.msg.campaign_version_target_status) {
    //     this.campaign_conf.campaign_version_target_status = res.msg.campaign_version_target_status;
    //   }
    // }
  }

  serialize_telepohny_users(data) {
    for (let i = 0; i < data.length; i++) {
      this.sip_storage.all_telephone_user.push(data[i].telephony_details);
    }
  }

  setValue(new_key: string, value: any): void  {
    this.values_data[new_key] = value;
  }

  getValue(key_name: string) {
    return this.values_data[key_name];
  }

  removeValue(key_name: string) {
    if (this.values_data[key_name]) {
      delete this.values_data[key_name];
    }
  }

  // sip getter/setter

  empty_sip_storage() {
    this.sip_storage.call_id = null;
    this.sip_storage.incoming_number = null;
    this.sip_storage.call_log_table_id = null;
    this.sip_storage.call_hold_id = null;
  }

  empty_sip_user() {
    this.sip_storage.uri = null;
    this.sip_storage.password = null;
    this.sip_storage.authorization_user = null;
    this.sip_storage.sip_ext = null;
  }

  reset_sip_store(){
    // future reset triggers add here
    this.sip_storage.cus_info_toggle = false;
  }

  set_sip_user(credentials) {
    console.log(credentials)
    this.sip_storage.uri = credentials.uri;
    this.sip_storage.password = credentials.password ? credentials.password : null;
    this.sip_storage.authorization_user = credentials.authorization_user ? credentials.authorization_user : null;
    this.sip_storage.sip_ext = credentials.sip_ext ? credentials.sip_ext : null;
  }

  set_sip_call_data(call_data) {
    this.sip_storage.call_id = call_data.call_id;
    this.sip_storage.incoming_number = call_data.incoming_number;
  }

  get_sip_call_data() {
    const call_data = {
      call_id: this.sip_storage.call_id,
      incoming_number: this.sip_storage.incoming_number
    }
    return call_data;
  }


  // ROLES CODE
  async promise_fn() {
    if (localStorage.getItem('token') != undefined && localStorage.getItem('token') != null) {
      // var res = await this._commonService.get_user_role_map({}).toPromise();
      // this.roles_map = res.msg.component_data;
      await this.get_data_after_refresh();
      // this.get_user_role_map();
      // return res;
    } else {
      this._router.navigate(['./sessions/signin']);
    }
  }

  async shared_services_before_routing(){
    var res = await this.promise_fn();

    // forced 1 second delay on refresh
    return of(res).pipe(delay(1000));

  }

  get_roles_map(){
    return this.roles_map;
  }

  check_dt_role_access(key) {
    var param = key.split("__");

    if(this.roles_map[param[0]] != undefined && this.roles_map[param[0]][param[1]] != undefined){
      if(this.roles_map[param[0]][param[1]] == 1){
        return true;
      } else {
        return false;
      }
    } else {
      return true;    //  should be false.. commiting for demo purpose.
    }
  }

  // info extractor sets-> ip,user device
  extract_info() {
    this.ip_getter();
    localStorage.setItem('device', this.getDeviceType());
    // to know the geolocation of device, supports only https 
    // navigator.geolocation.getCurrentPosition(this.showPos)
    // formatted result can be get using results[0].formatted;
  }

  ip_getter() {
    // open source api with no limitation.
    // ajax call
    $.getJSON('https://api.ipify.org?format=jsonp&callback=?', function (data) {
      localStorage.setItem('user_ip', data.ip);
    });
  }

  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  showPos(pos) {
    // console.log(pos)
  }

  cached_data:any = {
    
  }

  set_cached_data(service,params,response){
    switch (service) {
      case "/get_all_workflow_actions":
         if(this.cached_data[service] == undefined){
           this.cached_data[service] = {}
           this.cached_data[service][params['object_type']] = response
         }else if(this.cached_data[service][params['object_type']] == undefined){
             this.cached_data[service][params['object_type']] = response
         }
        break;
      case "/workflow/user/workgroups":
        if(this.cached_data[service] == undefined){
          this.cached_data[service] = {}
          this.cached_data[service][params['wrkgrp_type']] = response
        }else if(this.cached_data[service][params['wrkgrp_type']] == undefined){
            this.cached_data[service][params['wrkgrp_type']] = response
        }
        break;
      case "/get_signature_from_user_id":
        if(this.cached_data[service] == undefined){
          this.cached_data[service] = {}
          this.cached_data[service][params['user_id']] = response
        }else if(this.cached_data[service][params['user_id']] == undefined){
            this.cached_data[service][params['user_id']] = response
        }
      break;
      case "/get_task_classification_dropdown":
      case "/get_case_classification_dropdowns":
      case "/get_all_users_for_filters":
      case "/get_all_case_status":
        if(this.cached_data[service] == undefined){
           this.cached_data[service] = response
        }
        break;
      case "/get_classification_value_data_dropdown":
        if(this.cached_data[service] == undefined){
          this.cached_data[service] = {}
        }
        for (var i = response['msg'].length - 1; i >= 0; i--) {
          if(this.cached_data[service][response['msg'][i]['classification_type']] == undefined){
            this.cached_data[service][response['msg'][i]['classification_type']] = response['msg'][i]
          }
        }
        break;
      case '/email_content/get_all':
        if(this.cached_data[service] == undefined){
          this.cached_data[service] = {}
          this.cached_data[service][params['record_type']] = response
        } else if(this.cached_data[service][params['record_type']] == undefined){
          this.cached_data[service][params['record_type']] = response
        }
        break;
      case '/search_tags/get':
        if(this.cached_data[service] == undefined){
          this.cached_data[service] = {}
          this.cached_data[service][params['tagident']] = response
        } else if(this.cached_data[service][params['tagident']] == undefined){
          this.cached_data[service][params['tagident']] = response
        }
        break;
      case "/workflow/user/workgroups/object_status":
        if(this.cached_data[service] == undefined){
          this.cached_data[service] = {}
          this.cached_data[service][params['wrkgrp_type']] = response
        }else if(this.cached_data[service][params['wrkgrp_type']] == undefined){
            this.cached_data[service][params['wrkgrp_type']] = response
        }
      break;
    }
  }

  get_cached_data(service,params){
    let data = {}
    switch (service) {
      case "/get_all_workflow_actions":
        if(this.cached_data[service] != undefined && this.cached_data[service][params['object_type']] != undefined){
          data = this.cached_data[service][params['object_type']]
        }else{
          return {"status":false,"data":data}
        }
        break;

      case "/workflow/user/workgroups":
        if(this.cached_data[service] != undefined && this.cached_data[service][params['wrkgrp_type']] != undefined){
          data = this.cached_data[service][params['wrkgrp_type']] // from cache
        }else{
          return {"status":false,"data":data} // service call
        }
      break;

      case "/get_signature_from_user_id":
        console.log(this.cached_data[service]);
        if(this.cached_data[service] != undefined && this.cached_data[service][params['user_id']] != undefined){
          data = this.cached_data[service][params['user_id']] // from cache
        }else{
          return {"status":false,"data":data} // service call
        }
      break;

      case "/get_task_classification_dropdown":
      case "/get_case_classification_dropdowns":
      case "/get_all_users_for_filters":
      case "/get_all_case_status":
        if(this.cached_data[service] != undefined){
          data = this.cached_data[service]
        }else{
          return {"status":false,"data":data}
        }
        break;


      case "/get_classification_value_data_dropdown":
        if(this.cached_data[service] == undefined){
          return {"status":false,"data":data} 
        }else{
          let temp_data = this.cached_data[service]
          let res = []
          for (var i = params['classification_type_id'].length - 1; i >= 0; i--) {
            if(temp_data[params['classification_type_id'][i]] != undefined){
              res.push(temp_data[params['classification_type_id'][i]]) 
            }else{
              return {"status":false,"data":data}
            }
          }
          data = {
            "errCode":0,
            "msg":res
          }          
        }

        break;
      case "/email_content/get_all":
        if(this.cached_data[service] != undefined && this.cached_data[service][params['record_type']] != undefined){
          data = this.cached_data[service][params['record_type']]
        }else{
          return {"status":false,"data":data}
        }
        break;
  
      case '/search_tags/get':
        if(this.cached_data[service] != undefined && this.cached_data[service][params['tagident']] != undefined){
          data = this.cached_data[service][params['tagident']]
        }else{
          return {"status":false,"data":data}
        }
      break;
      case "/workflow/user/workgroups/object_status":
        if(this.cached_data[service] != undefined && this.cached_data[service][params['wrkgrp_type']] != undefined){
          data = this.cached_data[service][params['wrkgrp_type']]
        }else{
          return {"status":false,"data":data}
        }
        break;

    }

    return {"status":true,"data":data}
  }

  update_cached_data(service,params, new_record){
    switch (service) {
      case '/search_tags/get':
        if(this.cached_data[service] != undefined && this.cached_data[service][params['tagident']] != undefined){
          this.cached_data[service][params['tagident']]['data'].push(new_record)
        }
      break;
    }
  }

  clear_cached_data(){
    this.cached_data = {}
  }

  fetch_cached_data(){
    this.resetCache = false
    if(JSON.stringify(this.cached_data) === '{}'){
      this.get_classifications().subscribe(res => {
        this.cached_data = res
        this.resetCache = true
      })
    }
  }

  getToken(){
    if(this._env.use_local_storage){
      return localStorage.getItem('token')
    }else{
      return this.getValue('token')
    }
  }

  set_tenant(tenant_id) {
    this.tenant_obj.tenant_id = tenant_id;
    this.tenant_obj.tenantbizorg = this.get_child_tenants(this.get_tenant_list);
    this._router.navigate(['./dashboard/v1']);
  }

  set_default_tenant() {
    if(this.tenant_obj.tenants_list.length) {
      this.tenant_obj.tenant_id = this.tenant_obj.tenants_list[0].id;
      this.tenant_obj.default_tenant_id = this.tenant_obj.tenants_list[0].id;
      this.tenant_obj.tenantbizorg = this.get_child_tenants(this.get_tenant_list);
    }
  }

  set_tenant_list(tenants_data) {
    this.tenant_obj.tenants_list = tenants_data;
    this.set_default_tenant();
  }

  get get_tenant(): string {
    if(this.tenant_obj.tenant_id != null) {
      return this.tenant_obj.tenant_id.toString();
    }
    return '';
  }

  get get_default_tenant(): string {
    if(this.tenant_obj.default_tenant_id != null) {
      return this.tenant_obj.default_tenant_id.toString();
    }
    return '';
  }

  get get_child_tenant_id_list(): Array<any> {   
    return this.tenant_obj.tenantbizorg;
  }

  get get_tenant_list(): Array<any> {
    return this.tenant_obj.tenants_list;
  }

  get get_tenant_list_single_level(): Array<any> {
    const flat_data = JSON.parse(JSON.stringify(this.tenant_to_single_level(this.tenant_obj.tenants_list,-1)));
    return flat_data.map(d => { delete d['children']; return d;})
  }

  private tenant_to_single_level(data,level) {
    const flat_data = []
    level = level + 1;
    for(let  i=0 ;i< data.length;i++) {
      if(data[i].children.length) {
        const temp_data = data[i]
        temp_data['level'] = level;
        flat_data.push(temp_data);
        flat_data.push(...this.tenant_to_single_level(data[i].children,level));
      } else {
        const temp_data = data[i]
        temp_data['level'] = level;
        flat_data.push(temp_data);
      }
    }
    return flat_data
  }

  get_child_tenants(tenant_list) {
    const tenant_id = parseInt(this.get_tenant);
    let tenant_ids = [];
    for(let  i= 0 ; i < tenant_list.length;i++) {
      if(tenant_id == tenant_list[i].id) {
        tenant_ids.push(tenant_list[i].id)
        tenant_ids.push(...this.get_child_tenants_id(tenant_list[i].children))
      } else {
        if(tenant_list[i].children.length) {
          tenant_ids.push(...this.get_child_tenants(tenant_list[i].children))
        }
      }
    }
    return tenant_ids
  }

  get_child_tenants_id(tenant_list) {
    let tenant_ids = [];
    for(let  i= 0 ; i < tenant_list.length;i++) {
      tenant_ids.push(tenant_list[i].id)
      if(tenant_list[i].children.length) {
        tenant_ids.push(...this.get_child_tenants_id(tenant_list[i].children))
      }
    }
    return tenant_ids;
  }
}
