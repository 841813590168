import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutsModule } from './layouts/layouts.module';
import { ComponentsModule } from './components/components.module';
// import { ToastrModule } from 'ngx-toastr';
import { SharedDirectivesModule } from '../dce-directive/shared-directives.module';

const components = []

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    ComponentsModule,
    LayoutsModule,
    SharedDirectivesModule,
    // ToastrModule,
    FormsModule
  ],
  exports: [
    // ToastrModule
    ComponentsModule
  ]
})
export class DceSharedModule { }
