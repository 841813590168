import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthLayoutComponent } from './dce-shared/layouts/auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './dce-shared/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarLargeComponent } from './dce-shared/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

import { Resolver } from './resolver';
import { AuthGaurd } from './dce-gaurds/auth.guard';

let adminRoutes: Routes = [
  {
    path: 'classification',
    loadChildren: () => import('./dce-views/classification/classification.module').then(m => m.ClassificationModule)
  },
  {
    path: 'ls-metadata',
    loadChildren: () => import('./dce-views/es-metadata/es-metadata.module').then(m => m.EsMetadataModule)
  }
]

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./dce-views/sessions/sessions.module').then(m => m.SessionsModule)
      },
      
    ]
  },
  {
    path: '',
    resolve: { roles_map: Resolver },
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes, 
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./dce-views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
