import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonObject {
	
	// open advice & closed advice
	optical_dentistry = {
		'1':'Optical benefit',
		'2':'Specialized Dentistry',
		'3':'Conservative Dentistry',
	}
	_underwriting = {
		'1':' Late Joiner Penalty',
		'2':' 3 Month General Waiting period',
		'3':' 12 Months Pre-existing Waiting period',
		'4':' None'
	}
	day_to_day = {
		'1':'Freedom of choice',
		'2':' Provider Networks',
	}
	hospital = {
		"1":"Any private Hospitals",
		"2":"Specific Private and/or Public Hospitals",
		"3":"State Hospitals Benefits",
	}
	coverage = {
		'1.0': 'Covered at the scheme rate',
		'1.1': 'Covered at 1.1x the scheme rate',
		'1.5': 'Covered at 1.5x the scheme rate',
		'2.0': 'Covered at 2x the scheme rate',
		'3.0': 'Covered at 3x the scheme rate',
		'Unlimited': 'Covered at unlimited scheme rate'
	};
	day_to_day_extent = {
		"0":" Less than R10000",
		"1":" R10000 – R20000",
		"2":" R20000 – R30000",
		"3":" more than R30000",
		"-1":" No classification",
	}
	day_to_day_cover = {
		"0":"Yes",
		"1":"No",
	}
	gap_cover = {
		"0":"No",
		"1":"Yes",
	}

	// notification-module
	datatype_map = [
		{
			'type': 'NONE',
			'value': 'NONE'
		},
		{
			'type': 'INTEGER',
			'value': 'INTEGER'
		}, {
			'type': 'FLOAT',
			'value': 'FLOAT'
		}, {
			'type': 'STRING',
			'value': 'STRING'
		}, {
			'type': 'FLEXIBLE',
			'value': 'FLEXIBLE'
		}, {
			'type': 'LIST',
			'value': 'LIST'
		}, {
			'type': 'DICT',
			'value': 'DICT'
		}, {
			'type': 'DATE',
			'value': 'DATE'
		}, {
			'type': 'DATETIME',
			'value': 'DATETIME'
		}
	];


	//plan comparision
	months = [
		{ 'month': 'January', 'value': '01' },
		{ 'month': 'February', 'value': '02' },
		{ 'month': 'March', 'value': '03' },
		{ 'month': 'April', 'value': '04' },
		{ 'month': 'May', 'value': '05' },
		{ 'month': 'June', 'value': '06' },
		{ 'month': 'July', 'value': '07' },
		{ 'month': 'August', 'value': '08' },
		{ 'month': 'September', 'value': '09' },
		{ 'month': 'October', 'value': '10' },
		{ 'month': 'November', 'value': '11' },
		{ 'month': 'December', 'value': '12' }
	];
	benefits = [
		{
		  "name": "Plan Type",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "plan_type",
		}, {
		  "name": "Plan Operation - Hospitalisation",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "major_medical",
		}, {
		  "name": "Day-to-day",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "day_to_day",
		},
		{
		  "name": "Overall Annual Maximum",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "overall_annual_maximum_hospital",
		},
		{
		  "name": "Hospital Benefit",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 Private Hospital Care",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "private_hospital_care",
		}, {
		  "name": "2 Co-payment",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "co_payment_on_admission",
		}, {
		  "name": "3 Oncology",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "oncology",
		}, {
		  "name": "4 Organ Transplants",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "organ_transplants",
		}, {
		  "name": "5 Dialysis",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "dialysis",
		}, {
		  "name": "6 Maternity - Natural Birth",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "maternity_benefit_natural_birth",
		}, {
		  "name": "Elective Caesarean",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "maternity_benefit_elective_ceasarians",
		}, {
		  "name": "7 To take home medication",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "to_take_home_medication",
		}, {
		  "name": "8 Psychiatric Hospitalisation",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "psychiatric_hospitalisation",
		}, {
		  "name": "Radiology/Pathology/Prosthesis",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 Basic Radiology",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "basic_radiology_hospital",
		}, {
		  "name": "2 MRI, CT & PET Scans",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "mri_ct_and_pet_scans_hospital",
		}, {
		  "name": "3 Pathology",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "pathology_hospital",
		}, {
		  "name": "4 Internal Prosthesis",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "prosthesis_internal",
		}, {
		  "name": "5 External Appliances/Prosthesis",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "prosthesis_external",
		}, {
		  "name": "Sub Acute Facilities",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 Hospice",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "hospice",
		}, {
		  "name": "2 Nursing",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "private_nursing",
		}, {
		  "name": "3 Ambulance Services",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "ambulance_services",
		}, {
		  "name": "Chronic Benefit",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "27 CDL chronic conditions",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "cdl_chronic_conditions",
		}, {
		  "name": "Additional chronic conditions",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "additional_chronic_conditions",
		}, {
		  "name": "Day-to-day Benefit",
		  "type": "group",
		  "level": 1,
		  "key_map": "",
		}, {
		  "name": "Overall Annual Maximum",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "overall_annual_maximum_day_to_day",
		}, {
		  "name": "Preferred Provider	",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "preferred_provider",
		}, {
		  "name": "Medical Savings Account",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "medical_savings_account",
		}, {
		  "name": "Annual Threshold",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "annual_threshold",
		}, {
		  "name": "Self Payment Gap",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "self_payment_gap",
		}, {
		  "name": "Above Threshold Benefit",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "above_threshold_benefit",
		}, {
		  "name": "GP's and medication",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 General Practitioners",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "general_practitioners",
		}, {
		  "name": "2 Specialists",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "specialists",
		}, {
		  "name": "3 Prescribed Medication",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "prescribed_medication",
		}, {
		  "name": "4 Pharmacy Advised Medicine",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "pharmacy_advised_medication",
		}, {
		  "name": "Radiology & Pathology",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 Basic Radiology",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "basic_radiology_day_to_day",
		}, {
		  "name": "2 MRI, CT & PET Scans",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "mri_ct_and_pet_scans_day_to_day",
		}, {
		  "name": "3 Pathology",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "pathology_day_to_day",
		}, {
		  "name": "Dental Benefit",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 Conservative Dentistry",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "conservative_dentistry",
		}, {
		  "name": "2 Specialised Dentistry",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "specialised_dentistry",
		}, {
		  "name": "Optical Benefit",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 Examination",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "optical_benefit_examination",
		}, {
		  "name": "2 Lenses",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "optical_benefit_lenses",
		}, {
		  "name": "3 Frames",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "optical_benefit_frames",
		}, {
		  "name": "4 Contact Lenses",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "optical_benefit_contact_lenses",
		}, {
		  "name": "Auxiliary Services",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 Physiotherapy",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "physiotherapy",
		}, {
		  "name": "2 Psychiatry",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "psychiatry",
		}, {
		  "name": "3 Psychology",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "psychology",
		}, {
		  "name": "4 HIV/AIDS",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "hiv_aids",
		}, {
		  "name": "Financial and Demographic",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		}, {
		  "name": "1 Date of information",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "date_of_information",
		}, {
		  "name": "2 Principal Members",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "principal_members",
		}, {
		  "name": "3 Administrator",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "administrator",
		}, {
		  "name": "4 Scheme (Option) age profile",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "scheme_option_age_profile",
		}, {
		  "name": "5 Solvency ratio | Scheme growth",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "solvency_ratio",
		}, {
		  "name": "6 Past Scheme increases",
		  "type": "subgroup",
		  "level": 1,
		  "key_map": "past_increases",
		}
		, {
		  "name": "Contributions",
		  "type": "group",
		  "level": 0,
		  "key_map": "",
		},
		//  {
		//   "name": "Salary",
		//   "type": "subgroup",
		//   "level": 1,
		//   "key_map": "",
		// },
		{
		  "name": "Principal",
		  "type": "contributions",
		  "level": 1,
		  "key_map": "member_cost",
		}, {
		  "name": "Adult",
		  "type": "contributions",
		  "level": 1,
		  "key_map": "adult_share_risk_plus_saving",
		}, {
		  "name": "Child",
		  "type": "contributions",
		  "level": 1,
		  "key_map": "child_share_risk_plus_saving",
		}
	];
	gap_benefits = [
		{
		  'name': 'Administrator',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'administrator',
		}, {
		  'name': 'Underwriters',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'underwriters',
		}, {
		  'name': 'Overall Annual Limit',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Applies to all marked with *',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'applies_to_marked',
		}, {
		  'name': 'Specialist Services',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'In hospital Specialist Shortfalls',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'inhospital_specialist_shortfalls',
		}, {
		  'name': 'Specialist shortfalls for in hospital dental procedures',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'specialist_shortfalls_inhospital_dental_procedures',
		}, {
		  'name': 'Specialist shortfalls if a Non DSP service provider is used',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'specialist_shortfalls_nondsp_provider',
		}, {
		  'name': 'Specialist shortfalls for procedures in rooms',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'specialist_shortfalls_inroom_procedures',
		}, {
		  'name': 'Hospital penalty if a Non DSP is used',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'hospital_shortfalls_nondsp',
		}, {
		  'name': 'Hospital Benefits',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Additional hospital benefit/Listed procedures',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'miscellaneous_hospital_benefit',
		}, {
		  'name': 'Pre and Post Hospital Benefits',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Specialist consultations, ante-natal care, rehabilitation, TTO medicines, etc.',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'specialist_consultations_etc',
		}, {
		  'name': 'Sub Limits',
		  'type': 'group',
		  'level': 0,
		  'key_map': 'sub_limit_cover',
		}, {
		  'name': 'Internal prosthesis',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'internal_prosthesis',
		}, {
		  'name': 'MRI / CT / PET Scans sublimits',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'mri_ct_pet_scans_sublimits',
		}, {
		  'name': ' Co-payments/Deductibles',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Co-payment / Deductible cover',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'copayment_deductible_cover',
		}, {
		  'name': 'Co payments if paid by the scheme from savings',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'copayments_scheme_savings',
		}, {
		  'name': 'MRI / CT / PET Scans co payments',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'mri_ct_pet_scans_copayments',
		}, {
		  'name': 'Excluded procedures',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Cover for procedures excluded by medical scheme',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'scheme_excluded_procedure_cover',
		}, {
		  'name': 'Oncology',
		  'type': 'group',
		  'level': 1,
		  'key_map': '',
		}, {
		  'name': 'Shortfall if scheme pays percentage once oncology limit reached',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'shortfall_scheme_oncology_limit_percent',
		}, {
		  'name': 'Shortfall if scheme pays nil once oncology limit reached',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'shortfall_scheme_oncology_limit_nil',
		}, {
		  'name': 'Biological drugs as per formulary',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'biological_drugs_formulary',
		}, {
		  'name': 'Lump sum Cancer benefit',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'lump_sum_cancer_benefit',
		}, {
		  'name': 'Cosmetic breast reconstruction due to cancer',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'cosmetic_breast_reconstruction_cancer',
		}, {
		  'name': 'Casualty/Emergency room benefit',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Casualty / Emergency room facility benefit',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'casualty_er_benefit',
		}, {
		  'name': 'Medicines in Casualty',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'medicines_casualty',
		}, {
		  'name': 'MRI/CT scans in casualty',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'mri_ct_scans_in_casualty',
		}, {
		  'name': 'Extended benefits',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Additional care cover',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'additional_care_cover',
		}, {
		  'name': 'Appliances',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'appliances',
		}, {
		  'name': 'Dental benefit',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'accidental_dental_benefit',
		}, {
		  'name': 'Travel Cover',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'travel_cover',
		}, {
		  'name': 'Trauma counselling benefit, Hijack benefit, Crisis assistance',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'trauma_counselling_etc',
		}, {
		  'name': 'Emergency transport, Life support, Search and rescue, Body repatriation (all due to accident)',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'er_accident',
		}, {
		  'name': 'RAF claims',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'raf_claims',
		}, {
		  'name': 'Hospital cash benefit',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'hospital_cash_benefit',
		}, {
		  'name': 'Death/permanent disablement benefit',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'accidental_death_disablement',
		}, {
		  'name': 'Dread disease benefit',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'dread_disease_benefit',
		}, {
		  'name': 'Miscellaneous insured benefit',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'home_support_benefit',
		}, {
		  'name': 'Medical scheme contribution waiver',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'medical_scheme_contribution_waiver',
		}, {
		  'name': 'Gap Cover contribution waiver',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'gap_cover_contribution_waiver',
		}, {
		  'name': 'Payback of premiums',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'payback_of_premiums',
		}, {
		  'name': 'Waiting periods / Terms of cover',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'General waiting period',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'general_waiting_period',
		}, {
		  'name': 'Condition specific waiting period',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'condition_specific_waiting_period',
		}, {
		  'name': 'Pre-existing condition waiting period',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'pre-existing_condition_waiting_period',
		}, {
		  'name': 'Oncology waiting period',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'oncology_waiting_period',
		}, {
		  'name': 'Maximum entry age',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'maximum_entry_age',
		}, {
		  'name': 'Dependant eligibility',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'dependant_eligibility',
		}, {
		  'name': 'Claims submission period',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'claims_submission_period',
		}, {
		  'name': 'General Exclusions',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Hazardous pursuits excluded (specify)',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'hazardous_pursuits_excluded',
		}, {
		  'name': 'Contributions',
		  'type': 'group',
		  'level': 0,
		  'key_map': '',
		}, {
		  'name': 'Contribution',
		  'type': 'subgroup',
		  'level': 1,
		  'key_map': 'rate',
		}
	];

	// condition_map_for_workflow_actions

	condition_map = {
		'assign-success': {
			'my_cases': [
				{
					'action': 'update',
					'condition_set': {
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							}, {
								'field': 'owner_user_id',
								'value': 'selected_user_id',
								'operator': 'equal',
							}
						],
						'condition': 'AND'
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'owner_user_id',
								'value': 'selected_user_id',
								'operator': 'not_equal',
							}
						]
					}
				}
			],
			'wg_cases': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [

							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [

							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'not_equal',
							}
						]
					}
				}
			],
			'my_cases_all': [{
				'action': 'update',
				'condition_set': {
					'condition': 'AND',
					'rules': [
						{
							'field': 'wrkgrp_id_list',
							'value': 'wrkgrp_id',
							'operator': 'in',
						},
						{
							'field': 'owner_user_id',
							'value': 'selected_user_id',
							'operator': 'equal',
						}
					]
				}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'in',
							},
							{
								'field': 'owner_user_id',
								'value': 'selected_user_id',
								'operator': 'not_equal',
							}
						]
					}
				}
			],
			'wg_cases_all': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'selected_wrkgrp_id',
								'operator': 'in',
							}
						]
					}
				}
			],
			'my_leads': [
				{
					'action': 'update',
					'condition_set': {
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							}, {
								'field': 'owner_user_id',
								'value': 'selected_user_id',
								'operator': 'equal',
							}
						],
						'condition': 'AND'
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'owner_user_id',
								'value': 'selected_user_id',
								'operator': 'not_equal',
							}
						]
					}
				}
			],
			'wg_leads': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [

							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [

							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'not_equal',
							}
						]
					}
				}
			],
			'my_leads_all': [{
				'action': 'update',
				'condition_set': {
					'condition': 'AND',
					'rules': [
						{
							'field': 'wrkgrp_id_list',
							'value': 'wrkgrp_id',
							'operator': 'in',
						},
						{
							'field': 'owner_user_id',
							'value': 'selected_user_id',
							'operator': 'equal',
						}
					]
				}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'in',
							},
							{
								'field': 'owner_user_id',
								'value': 'selected_user_id',
								'operator': 'not_equal',
							}
						]
					}
				}
			],
			'wg_leads_all': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'selected_wrkgrp_id',
								'operator': 'in',
							}
						]
					}
				}
			]
		},
		'dispatch-success': {
			'my_cases': [
				{
					'action': 'remove',
					'condition_set': {
						// no conditions required to remove; 

						// 'rules': [
						// 	{
						// 		'field': 'wrkgrp_id',
						// 		'value': 'selected_wrkgrp_id',
						// 		'operator': 'equal',
						// 	},
						// 	{
						// 		'field': 'wrkgrp_id',
						// 		'value': 'selected_wrkgrp_id',
						// 		'operator': 'not_equal',
						// 	}
						// ],
						// 'condition': 'OR'
					}
				}],
			'wg_cases': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'selected_wrkgrp_id',
								'value': 'prev_wrkgrp_id',
								'operator': 'not_equal',
							}
						]
					}
				},
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'selected_wrkgrp_id',
								'value': 'prev_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'not_equal',
							},
						]
					}
				}
			],
			'my_cases_all': [
				{
					'action': 'remove',
					'condition_set': {
						// no conditions required to remove.
					}
				}
			],
			'wg_cases_all': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'in',
							},
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'not_in',
							},
						]
					}
				}
			],
			'my_leads': [
				{
					'action': 'remove',
					'condition_set': {
						// no conditions required to remove; 

						// 'rules': [
						// 	{
						// 		'field': 'wrkgrp_id',
						// 		'value': 'selected_wrkgrp_id',
						// 		'operator': 'equal',
						// 	},
						// 	{
						// 		'field': 'wrkgrp_id',
						// 		'value': 'selected_wrkgrp_id',
						// 		'operator': 'not_equal',
						// 	}
						// ],
						// 'condition': 'OR'
					}
				}],
			'wg_leads': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'selected_wrkgrp_id',
								'value': 'prev_wrkgrp_id',
								'operator': 'not_equal',
							}
						]
					}
				},
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'selected_wrkgrp_id',
								'value': 'prev_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'not_equal',
							},
						]
					}
				}
			],
			'my_leads_all': [
				{
					'action': 'remove',
					'condition_set': {
						// no conditions required to remove.
					}
				}
			],
			'wg_leads_all': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'in',
							},
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'not_in',
							},
						]
					}
				}
			]
		},
		'accept-success': {
			'my_cases': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',

							}
						]
					}
				},
			],
			'wg_cases': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
						]
					}
				},
			],
			'my_cases_all': [
				{
					'action': 'create',
					'condition_set': {
						// no condition required to create
					}
				},
			],
			'wg_cases_all': [
				{
					'action': 'update',
					'condition_set': {
						// no condition required to update
					}
				},
			],
			'my_leads': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',

							}
						]
					}
				},
			],
			'wg_leads': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
						]
					}
				},
			],
			'my_leads_all': [
				{
					'action': 'create',
					'condition_set': {
						// no condition required to create
					}
				},
			],
			'wg_leads_all': [
				{
					'action': 'update',
					'condition_set': {
						// no condition required to update
					}
				},
			]
		},
		'reject-success': {
			'my_cases': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'owner_user_id',
								'value': 'selected_user_id',
								'operator': 'equal',
							}
						]
					}
				},
			],
			'wg_cases': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'selected_wrkgrp_id',
								'value': 'prev_wrkgrp_id',
								'operator': 'not_equal',
							},
						]
					}
				},
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'selected_wrkgrp_id',
								'value': 'prev_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'not_equal',
							}
						]
					}
				},

			],
			'my_cases_all': [{
				'action': 'create',
				'condition_set': {
					'condition': 'AND',
					'rules': [
						{
							'field': 'wrkgrp_id_list',
							'value': 'wrkgrp_id',
							'operator': 'in',
						},
						{
							'field': 'owner_user_id',
							'value': 'selected_user_id',
							'operator': 'equal',
						}
					]
				}
			}
			],
			'wg_cases_all': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'in',
							}
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'not_in',
							}
						]
					}
				}
			],
			'my_leads': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'owner_user_id',
								'value': 'selected_user_id',
								'operator': 'equal',
							}
						]
					}
				},
			],
			'wg_leads': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'selected_wrkgrp_id',
								'value': 'prev_wrkgrp_id',
								'operator': 'not_equal',
							},
						]
					}
				},
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							},
							{
								'field': 'selected_wrkgrp_id',
								'value': 'prev_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'not_equal',
							}
						]
					}
				},

			],
			'my_leads_all': [{
				'action': 'create',
				'condition_set': {
					'condition': 'AND',
					'rules': [
						{
							'field': 'wrkgrp_id_list',
							'value': 'wrkgrp_id',
							'operator': 'in',
						},
						{
							'field': 'owner_user_id',
							'value': 'selected_user_id',
							'operator': 'equal',
						}
					]
				}
			}
			],
			'wg_leads_all': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'in',
							}
						]
					}
				},
				{
					'action': 'remove',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id_list',
								'value': 'wrkgrp_id',
								'operator': 'not_in',
							}
						]
					}
				}
			]
		},
		'yank-success': {
			'my_cases': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				}
			],
			'wg_cases': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				}
			],
			'my_cases_all': [
				{
					'action': 'create',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'wg_cases_all': [
				{
					'action': 'update',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'my_leads': [
				{
					'action': 'create',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				}
			],
			'wg_leads': [
				{
					'action': 'update',
					'condition_set': {
						'condition': 'AND',
						'rules': [
							{
								'field': 'wrkgrp_id',
								'value': 'selected_wrkgrp_id',
								'operator': 'equal',
							}
						]
					}
				}
			],
			'my_leads_all': [
				{
					'action': 'create',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'wg_leads_all': [
				{
					'action': 'update',
					'condition_set': {
						// no condition required to remove
					}
				}
			]
		},
		'close-success': {
			'my_cases': [
				{
					'action': 'remove',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'wg_cases': [
				{
					'action': 'remove',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'my_cases_all': [
				{
					'action': 'remove',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'wg_cases_all': [
				{
					'action': 'remove',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'my_leads': [
				{
					'action': 'remove',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'wg_leads': [
				{
					'action': 'remove',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'my_leads_all': [
				{
					'action': 'remove',
					'condition_set': {
						// no condition required to remove
					}
				}
			],
			'wg_leads_all': [
				{
					'action': 'remove',
					'condition_set': {
						// no condition required to remove
					}
				}
			]
		}
	}; 

	constructor() { }
}
