import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
//import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { ToastrModule } from 'ngx-toastr';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { InPlaceConfirmationComponent } from './in-place-confirmation/in-place-confirmation.component';
import { MultiLevelClassificationComponent } from './multi-level-classification/multi-level-classification.component';
import { NgCompleterV2Component } from './ng-completer-v2/ng-completer-v2.component';
import { PagniatedDatatableComponent } from './pagniated-datatable/pagniated-datatable.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SideContainerComponent } from './side-container/side-container.component';
import { ShowErrorsComponent } from './show-errors/show-errors.component';
import { TogglerComponent } from './toggler/toggler.component';
import { TreeComponent } from './tree/tree.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { SharedDirectivesModule } from 'src/app/dce-directive/shared-directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { InfoComponent } from './info/info.component';
import { InstanceTypeComponent } from './instance-type/instance-type.component';
import { CancelEditComponent } from './cancel-edit/cancel-edit.component';
import { DatatableSearchComponent } from './datatable-search/datatable-search.component'
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
const components = [
  BreadcrumbComponent,
  ConfirmationModalComponent,
  InPlaceConfirmationComponent,
  MultiLevelClassificationComponent,
  NgCompleterV2Component,
  PagniatedDatatableComponent,
  ShowErrorsComponent,
  SpinnerComponent,
  SideContainerComponent,
  TogglerComponent,
  TreeComponent,
  UploadDocumentComponent,
  InfoComponent,
  InstanceTypeComponent,
  CancelEditComponent,
  DatatableSearchComponent,
  BtnLoadingComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedPipesModule,
    SharedDirectivesModule,
    FormsModule,
    ReactiveFormsModule,

    AutocompleteLibModule,
    FontAwesomeModule,
    NgxDatatableModule,
   // PerfectScrollbarModule,
   // NgbModule,
  //  ToastrModule
  ],
  declarations: components,
  exports: components
})
export class ComponentsModule { }
