import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from './env.service';
import { ProjectService } from './project.service';
import { CommonObject } from './common-object';

declare var $: any;

@Injectable({
	providedIn: 'root'
})
export class CommonService extends ProjectService {
	
	constructor(
		protected http: HttpClient,
		protected comObj: CommonObject,
		protected env: EnvService,
		) { 
			// tslint:disable-next-line:indent
			super(http, env);
		}
	

		//QueryComponent services
		private get_all_queries_url = `${this.env.service_url}/meta/query/get_all`;
		get_all_queries(payload) {
			return this.http.post<any>( this.get_all_queries_url , payload)
		}

		private get_query_data_url = `${this.env.service_url}/meta/query/get`;
		get_query_data(payload) {
			return this.http.post<any>( this.get_query_data_url , payload)
		}

		private create_query_data_url = `${this.env.service_url}/meta/query/create`;
		create_query_data(payload) {
			return this.http.post<any>( this.create_query_data_url , payload)
		}

		private update_query_data_url = `${this.env.service_url}/meta/query/update`;
		update_query_data(payload) {
			return this.http.post<any>( this.update_query_data_url , payload)
		}

		private remove_query_data_url = `${this.env.service_url}/meta/query/remove`;
		remove_query_data(payload) {
			return this.http.post<any>( this.remove_query_data_url , payload)
		}


		///////////////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////////////
		// Message Type Service Calls

		private create_message_type_url = `${this.env.service_url}/meta/message_type/create`;
		create_message_type(payload) {
			return this.http.post<any>( this.create_message_type_url , payload)
		}

		private remove_message_type_url = `${this.env.service_url}/meta/message_type/remove`;
		remove_message_type(payload) {
			return this.http.post<any>( this.remove_message_type_url , payload)
		}

		public update_message_type_url = `${this.env.service_url}/meta/message_type/update`;
		update_message_type(payload) {
			return this.http.post<any>( this.update_message_type_url , payload)
		}

		private get_all_message_types_url = `${this.env.service_url}/meta/message_type/get_all`;
		get_all_message_types(payload) {
			return this.http.post<any>( this.get_all_message_types_url , payload)
		}

		private get_message_type_by_id_url = `${this.env.service_url}/meta/message_type/get`;
		get_message_type_by_id(payload) {
			return this.http.post<any>( this.get_message_type_by_id_url , payload)
		}

		// JSON Transform Service Calls
		private create_json_transform_url = `${this.env.service_url}/meta/transform/create`;
		create_json_transform(payload) {
			return this.http.post<any>( this.create_json_transform_url , payload)
		}

		private remove_json_transform_url = `${this.env.service_url}/meta/transform/remove`;
		remove_json_transform(payload) {
			return this.http.post<any>( this.remove_json_transform_url , payload)
		}

		public update_json_transform_url = `${this.env.service_url}/meta/transform/update`;
		update_json_transform(payload) {
			return this.http.post<any>( this.update_json_transform_url , payload)
		}

		private get_all_json_transform_url = `${this.env.service_url}/meta/transform/get_all`;
		get_all_json_transform(payload) {
			return this.http.post<any>( this.get_all_json_transform_url , payload)
		}

		private get_json_transform_by_id_url = `${this.env.service_url}/meta/transform/get`;
		get_json_transform_by_id(payload) {
			return this.http.post<any>( this.get_json_transform_by_id_url , payload)
		}

		//Search Index
		private create_search_index_url = `${this.env.service_url}/meta/search/index/create`;
		create_search_index(payload) {
			return this.http.post<any>( this.create_search_index_url , payload)
		}

		private remove_search_index_url = `${this.env.service_url}/meta/search/index/remove`;
		remove_search_index(payload) {
			return this.http.post<any>( this.remove_search_index_url , payload)
		}

		public update_search_index_url = `${this.env.service_url}/meta/search/index/update`;
		update_search_index(payload) {
			return this.http.post<any>( this.update_search_index_url , payload)
		}

		private get_all_search_index_url = `${this.env.service_url}/meta/search/index/get_all`;
		get_all_search_index(payload) {
			return this.http.post<any>( this.get_all_search_index_url , payload)
		}

		private get_search_index_by_id_url = `${this.env.service_url}/meta/search/index/get`;
		get_search_index_by_id(payload) {
			return this.http.post<any>( this.get_search_index_by_id_url , payload)
		}

		//ManageSearchIndexAttributesComponent api's
		private get_all_search_index_attributes_url = `${this.env.service_url}/meta/search/index/attributes/get_all`;
		get_all_search_index_attributes(payload) {
			return this.http.post<any>( this.get_all_search_index_attributes_url , payload)
		}
		private get_search_index_attributes_url = `${this.env.service_url}/meta/search/index/attributes/get`;
		get_search_index_attributes(payload) {
			return this.http.post<any>( this.get_search_index_attributes_url , payload)
		}
		private remove_search_index_attributes_url = `${this.env.service_url}/meta/search/index/attributes/remove`;
		remove_search_index_attributes(payload) {
			return this.http.post<any>( this.remove_search_index_attributes_url , payload)
		}
		private create_search_index_attributes_url = `${this.env.service_url}/meta/search/index/attributes/create`;
		create_search_index_attributes(payload) {
			return this.http.post<any>( this.create_search_index_attributes_url , payload)
		}
		public update_search_index_attributes_url = `${this.env.service_url}/meta/search/index/attributes/update`;
		update_search_index_attributes(payload) {
			return this.http.post<any>( this.update_search_index_attributes_url , payload)
		}
		private get_history_message_type_url = `${this.env.service_url}/history/message_type/get`;
		get_history_message_type(payload) {
			return this.http.post<any>( this.get_history_message_type_url , payload)
		}
		private get_all_tooltips_url = `${this.env.service_url}meta/tooltips/get_all`;
		get_all_tooltips(payload) {
			return this.http.post<any>( this.get_all_tooltips_url , payload)
		}

		//Search Index Bulkload API
		private create_search_index_bulkload_url = `${this.env.service_url}/meta/search/index/bulkloads/create`;
		create_search_index_bulkload(payload) {
			return this.http.post<any>( this.create_search_index_bulkload_url , payload)
		}
		public update_search_index_bulkload_url = `${this.env.service_url}/meta/search/index/bulkloads/update`;
		update_search_index_bulkload(payload) {
			return this.http.post<any>( this.update_search_index_bulkload_url , payload)
		}
		private remove_search_index_bulkload_url = `${this.env.service_url}/meta/search/index/bulkloads/remove`;
		remove_search_index_bulkload(payload) {
			return this.http.post<any>( this.remove_search_index_bulkload_url , payload)
		}
		private get_search_index_bulkload_url = `${this.env.service_url}/meta/search/index/bulkloads/get`;
		get_search_index_bulkload(payload) {
			return this.http.post<any>( this.get_search_index_bulkload_url , payload)
		}
		private get_all_search_index_bulkload_url = `${this.env.service_url}/meta/search/index/bulkloads/get_all`;
		get_all_search_index_bulkload(payload) {
			return this.http.post<any>( this.get_all_search_index_bulkload_url , payload)
		}
		private get_bulkload_history_url = `${this.env.service_url}/history/bulk_load/get`;
		get_bulkload_history(payload) {
			return this.http.post<any>( this.get_bulkload_history_url , payload)
		}
		private perform_bulkload_url = `${this.env.service_url}/bulk_load/index/id`;
		perform_bulkload(payload) {
			return this.http.post<any>( this.perform_bulkload_url , payload)
		}

		// Extra API
		private _get_internal_user_details_refresh_url = `${this.env.service_url}/get_internal_user_details_refresh`;
		get_data_after_refresh(payload) {
			return this.http.post<any>( this._get_internal_user_details_refresh_url, payload);
		}	

		private get_all_telephony_users_service_url = `${this.env.service_url}/telephony/details/all_user`;
		get_all_telephony_users(payload) {
			return this.http.post<any>(this.get_all_telephony_users_service_url, payload);
		}

		private get_user_default_workgroup_service_url = `${this.env.service_url}/workflow/user/workgroups/default`;
		get_user_default_workgroup(payload) {
			return this.http.post<any>(this.get_user_default_workgroup_service_url, payload);
		}

		private get_user_role_map_url = `${this.env.service_url}/get_user_role_map`;
		get_user_role_map(payload) {
			return this.http.post<any>( this.get_user_role_map_url, payload);
		}

		private get_task_classification_dropdown_url = `${this.env.service_url}/get_task_classification_dropdown`;
		get_task_classification_dropdown(payload){
			return this.http.post<any>( this.get_task_classification_dropdown_url , payload)			
		}

		private get_case_classification_dropdowns_url = `${this.env.service_url}/get_case_classification_dropdowns`;
		get_case_classification_dropdowns(payload) {
			return this.http.post<any>( this.get_case_classification_dropdowns_url , payload)
		}
			
		private forgot_password_url  = `${this.env.service_url}/forgot_password`;
		forgot_password(payload){
			return this.http.post<any>( this.forgot_password_url, payload);
		}

		private check_password_availablity_url  = `${this.env.service_url}/check_password_already_used`;
		check_password_availablity(payload){
			return this.http.post<any>( this.check_password_availablity_url, payload);
		}
	
		private send_forgot_password_email_url = `${this.env.service_url}/send_forgot_password_email`
		send_forgot_password_email(payload){
			return this.http.post<any>( this.send_forgot_password_email_url, payload);
		}

		//Load guid API
		private check_existing_guid_url = `${this.env.service_url}/check_existing/load/guid`
		check_existing_guid(payload){
			return this.http.post<any>( this.check_existing_guid_url, payload);
		}

		private load_guid_url = `${this.env.service_url}/load/index/guid`
		load_guid(payload){
			return this.http.post<any>( this.load_guid_url, payload);
		}

		//Search Graph

		private get_search_schema_viz_url = `${this.env.service_url}/meta/search/schema/get`
		get_search_schema_viz(payload){
			return this.http.post<any>( this.get_search_schema_viz_url, payload);
		}
		///////////////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////////////



	// Common Functions

		//replace_empty_values
		replace_empty_values(res, keys, replace_with) {

			for(let i=0;i<res.length;i++){
				for(var j=0; j<keys.length; j++){
					if(res[i][keys[j]] == '' || res[i][keys[j]] == null){
						res[i][keys[j]] = replace_with;
					}
				}
			}

			return res;

		}

		filter_data_in_datatable(val, column_list, data) {

			let data_list = [];
			if (val) {
				val = val.trim().toLowerCase();
			} else {
				return data_list = [...data];
			}

			if (!column_list.length) {
				return;
			}

			const rows = data.filter(function (d) {
				for (let i = 0; i <= column_list.length; i++) {
					const column = column_list[i];
					if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
						return true;
					}
				}
			});
			data_list = rows;
			return data_list;
		}

		date_formatter(event){
			let new_date = '';
			for ( var key in event) {
			  if (key == 'day') {
				new_date+= event[key];
			  } else  if (key == 'month' || key == 'year'){
				new_date+= event[key]+ '-';
			  }
			}
			return new_date;
		}

		date_validation(start_date, end_date) {
			const sdate = new Date(start_date);
			const edate = new Date(end_date);
			return (sdate.getTime() < edate.getTime()) ? true : false;
		}

		date_validation_eq(start_date, end_date) {
			const sdate = new Date(start_date);
			const edate = new Date(end_date);
			return (sdate.getTime() <= edate.getTime()) ? true : false;
		}


		qry_builder_decision_to_backend_old(decision_data, data_type_map){
			var condition_map = {
				"AND": 'and',
				"OR": 'or',
				"NOT": 'not'
			};

			var operator_map = {
				"equal": '==',
				"not_equal": '!=',
				"less": '<',
				"less_or_equal": '<=',
				"greater": '>',
				"greater_or_equal": '>=',
				"is_null":"is_null",
				"is_not_null":"is_not_null",
				'in':'in',
				'not_in':'not_in',
				// 'between':'between',
				// 'not_between':'not_between',
				// 'begins_with':'begins_with',
				// 'not_begins_with':'not_begins_with',
				'contains':'contains',
				'not_contains':'not_contains',
				// 'ends_with':'ends_with',
				// 'not_ends_with':'not_ends_with',
				// 'is_empty':'is_empty',
				// 'is_not_empty':'is_not_empty',
			};

			// console.log(decision_data);
			var ret_val = {};
			var temp_list = [];
			var condition = decision_data['condition'];
			var rules = decision_data['rules'];
			var ret = {};
			var final_list = [];
			var d_type = 'str';

			for(var i=0; i<rules.length; i++){
				if(rules[i]['condition'] != undefined){
					ret = this.qry_builder_decision_to_backend_old(rules[i], data_type_map);
				} else {
					d_type = "str";
					if(data_type_map[rules[i]['id']]){
						d_type = data_type_map[rules[i]['id']];
					}
					temp_list.push({
						"operator": operator_map[rules[i]['operator']],
						"type": "str",	//	not required
						"data_type": data_type_map[rules[i]['id']],
						"op1": rules[i]['id'],
						"op2": rules[i]['value']
					});
				}
			}
			if(!$.isEmptyObject(ret)){
				final_list = temp_list.concat(ret);
			} else {
				final_list = temp_list;
			}

			ret_val[condition_map[condition]] = final_list;
			// console.log(condition);
			// console.log(final_list);
			// console.log(ret_val);
			// console.log("-------");
			// debugger
			return ret_val;
		}

		qry_builder_decision_to_backend(decision_data, data_type_map){
			var condition_map = {
				"AND": 'and',
				"OR": 'or',
				"NOT": 'not'
			};

			var operator_map = {
				"equal": '==',
				"not_equal": '!=',
				"less": '<',
				"less_or_equal": '<=',
				"greater": '>',
				"greater_or_equal": '>=',
				"is_null":"is_null",
				"is_not_null":"is_not_null",
				'in':'in',
				'not_in':'not_in',
				'between':'between',
				'not_between':'not_between',
				'begins_with':'begins_with',
				'not_begins_with':'not_begins_with',
				'contains':'contains',
				'not_contains':'not_contains',
				'ends_with':'ends_with',
				'not_ends_with':'not_ends_with',
				'is_empty':'is_empty',
				'is_not_empty':'is_not_empty',
			};

			var ret_val = {};
			var obj = {};

			if(decision_data.condition != undefined){
				ret_val[condition_map[decision_data.condition]] = []
				for(var i=0; i<decision_data.rules.length; i++){
					obj = this.qry_builder_decision_to_backend(decision_data.rules[i], data_type_map)
					ret_val[condition_map[decision_data.condition]].push(obj)
				}
				return ret_val;

			} else {
				return {
					"operator": operator_map[decision_data['operator']],
					"type": "str",	//	not required
					"data_type": data_type_map[decision_data['id']],
					"op1": decision_data['id'],
					"op2": decision_data['value']
				}
			}
		}
		
		//Quote To RFQ Workgroup Relation
		private add_rfq_to_quote_workgroup_url = `${this.env.service_url}/add_rfq_to_quote_workgroup`;
		add_rfq_to_quote_workgroup(payload) {
			return this.http.post<any>( this.add_rfq_to_quote_workgroup_url, payload);
		}
		private get_rfq_quote_map_url = `${this.env.service_url}/get_rfq_quote_map`;
		get_rfq_quote_map() {
			return this.http.post<any>( this.get_rfq_quote_map_url,{});
		}

		onSortDT(event,data){
			const rows = [...data];
			const sort = event.sorts[0];
			rows.sort((a, b) => {
			  return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
			});
			return rows
		}

		sortByKey(array, key, order) {
			return array.sort(function(a, b) {
				var x = a[key]; var y = b[key];
				// if(x<y) {
				// 	return order == 'asc'? -1: 1
				// } else if (x>y) {
				// 	return order == 'asc'? 1: -1
				// } else {
				// 	return 0;
				// }
				if (order == 'asc') {
					return ((x < y) ? -1 : ((x > y) ? 1 : 0));
				} else if (order == 'desc') {
					return ((x < y) ? 1 : ((x > y) ? -1 : 0));
				}
			});
	}

		// Pass data in array e.g ['select', 'input', 'textarea'];
		add_title_attribute(tag_list) {
			const html_tags = {
				'input': function(){
						$("input").each(function(){
							$(this).attr("title", $(this).val());
						});
				},
				'textarea': function() { 
						$("textarea").each(function(){
							$(this).attr("title", $(this).val());
						});
				},
				'select': function(){	
						$("select").each(function(){
							$(this).attr("title", $(this).find(":selected").text());
						});
				}
			}
			for(let i = 0;i< tag_list.length; i++) {
				setTimeout(() => {
					html_tags[tag_list[i]]();
				}, 200);
			}

		}
		onChanged($event){
			setTimeout(function(){
			  if(!$('.autocomplete-container').hasClass('active')){
			    $('.suggestions-container').hide()
			    $('.not-found').hide()
			  }else{
			    $('.suggestions-container').show()
			    $('.not-found').show()
			  }
			},10);
		}

		changeSortSettings(col,sort_type,_case) {
			localStorage.setItem(_case, JSON.stringify([{prop: col, dir: sort_type}]));
		}

		getSortSettings(_case) {
			if (localStorage.getItem(_case)) {
				return JSON.parse(localStorage.getItem(_case));
			}
		}

		formatDate(date) {
			var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();
	
			if (month.length < 2) 
					month = '0' + month;
			if (day.length < 2) 
					day = '0' + day;
	
			return [year, month, day].join('-');
		}
	
		jump_to_error() {
			setTimeout(() => {
				let invalidFields = [].slice.call(document.getElementsByClassName('app-error-class'));
				// console.log(invalidFields);
				$('html, body').animate({
					scrollTop: $(invalidFields[0]).offset().top - 130
				}, 500);
			}, 100);
		}

		select_row(dt_object_list,value_to_compare,key_to_compare){
		    if(dt_object_list.length > 0){
		      for (var i = dt_object_list.length - 1; i >= 0; i--) {
		        if(dt_object_list[i][key_to_compare] == value_to_compare){
		          return [dt_object_list[i]]
		        }
		      }
		    }
		    return []
			}

			autocomplete_value_exist(data_list,search_keyword, formNameValue) {
				if (typeof(formNameValue) == 'object'){
					for (let i =0 ;i< data_list.length;i++) {
						if (data_list[i][search_keyword] == formNameValue[search_keyword]) {
							return {status: true,value: formNameValue};
						}
					}
				} else if (typeof(formNameValue) == 'string') {
					for (let i =0 ;i< data_list.length;i++) {
						if (data_list[i][search_keyword] == formNameValue) {
							return {status: true,value: formNameValue};
						}
					}
				}
				return {status: false,value: null};
			}

	download_files(dataurl, target, filename) {
		var a = document.createElement("a");
		a.target = target;
		a.href = dataurl;
		a.setAttribute("download", filename);
		a.click();
	}

	date_to_string_for_month(event) {
		const y = event.getFullYear(), m = event.getMonth();
		const firstdate = new Date(y, m, 1);
		const lastdate = new Date(y, m + 1, 0);
		const firstdate_string = firstdate.getFullYear() + '-' + (firstdate.getMonth() + 1) + '-' +firstdate.getDate();
		const lastdate_string = lastdate.getFullYear() + '-' + (lastdate.getMonth() + 1) + '-' +lastdate.getDate();
		return [firstdate_string, lastdate_string];
	}

	date_to_string_for_week(date) {
		var first = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
		var last = first + 6; // last day is the first day + 6
		var firstday = new Date(date.setDate(first));
		var lastday = new Date(date.setDate(last));
		const firstdate_string = firstday.getFullYear() + '-' + (firstday.getMonth() + 1) + '-' +firstday.getDate();
		const lastdate_string = lastday.getFullYear() + '-' + (lastday.getMonth() + 1) + '-' +lastday.getDate();
		return [firstdate_string, lastdate_string];
	  }
	
	  date_to_string_for_day(date) {
		const firstdate_string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +date.getDate();
		const lastdate_string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +date.getDate();
		return [firstdate_string, lastdate_string];
	  }
	datatable_empty_msg(datatable_records, val, datatable_msg){
      if(datatable_records.length == 0){
        if(val == ''){
          datatable_msg.emptyMessage="There are no records."
        }else{
          datatable_msg.emptyMessage="No records matched for entered search criteria. Please try a new search."
        }	
      }
    }

	process_get_req(route, payload) {
		return `${route}?${this.object_to_query_param(payload)}`;
	  }
	
	object_to_query_param(obj) {
		let query_param = "";
		for (let key in obj) {
			query_param += key + "=" + obj[key] + '&'
		}
		return query_param.slice(0, -1)
	}
}
