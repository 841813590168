import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/dce-service/common.service';

// private values accesible only in this ts.
// public value can be accessible in other component ts.

const mainConfig = {
  l1: {"name": "Level 1","class":"col-4"},
  l2: {"name": "Level 2","class":"col-4"},
  l3: {"name": "Level 3","class":"col-4"},
  l4: {"name": "Level 4","class":"col-4"},
  l5: {"name": "Level 5","class":"col-4"},
}

@Component({
  selector: 'app-multi-level-classification',
  templateUrl: './multi-level-classification.component.html',
  styleUrls: ['./multi-level-classification.component.scss']
})

export class MultiLevelClassificationComponent implements OnInit, OnChanges {
  
  @Input() wf_type?: string = 'case';
  @Input() config: any = mainConfig; // classification level config
  @Input() editable = true; // helps in enable/disable form fields
  @Input() mandatory = true; // helps in enable/disable form fields
  @Input() allowed_classfication: any = { start:1} // accept only 1 to 5 end/ Currently it is only 5
  @Input() default_values: any = {} // used only when allowed classification is not start with 1
  @Input() unique_id?:string = ''; // unique id in html if we are using more than one comoponent at a time

  allowed_classification_end = 5
  // @Output() task_screen_events: EventEmitter<any> = new EventEmitter<any>();

  private classification_heirarchy = [];
  private classification_data = [];
  private classification_root = -1;

  public class_dropdown = []

  public classification_payload: FormGroup;
  public submitted_classification_payload = false;

  public allowed_levels:any = {l1:false, l2:false, l3:false, l4:false, l5:false}

  constructor(
    public _commonService: CommonService,
    private _fb: FormBuilder,
  ) { 
    this.initialize_payload();
  }

  private initialize_payload() {
    this.classification_payload = this._fb.group({
      'classification_l1': this._fb.control(null),
      'classification_l2':this._fb.control(null),
      'classification_l3':this._fb.control(null),
      'classification_l4':this._fb.control(null),
      'classification_l5':this._fb.control(null),
    });
  }

  ngOnInit() {
    switch(this.wf_type) {
      case 'case':
        this.get_case_classification_dropdown();
      break;
      case 'task':
        this.get_task_classification_dropdown();
      break;
      case 'mobile-case':
        this.get_case_classification_dropdown();
      break;
      default:
      break;
    }
    this.toggle_form(this.editable);
  }

  private get_task_classification_dropdown(){
    this._commonService.get_task_classification_dropdown({}).subscribe(res => {
      this.classification_heirarchy = res.parent_children_dict;
      this.classification_data = res.msg;
      this.classification_root = res.root_id
      this.populate_next_dropdown({},1);
      this.set_default_value_of_classification()
    })
  }

  private get_case_classification_dropdown() {
    var res = this._commonService.get_case_classification_dropdowns({}).subscribe(res => {
      if(res.errCode == 0) {
        this.classification_heirarchy = res.parent_children_dict;
        this.classification_data = res.msg;
        this.classification_root = res.root_id;
        this.populate_next_dropdown({}, 1);
        this.set_default_value_of_classification()
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editable) { this.toggle_form(!changes.editable.currentValue);}
    if(changes.allowed_classfication) { this.add_validation(); }
    if(changes.config) {this.set_defaults_value(); }
  }

  set_default_value_of_classification() {
    if(this.allowed_classfication.start > 1) {
      for(let  i= 1; i<= this.allowed_classfication.start;i++) {
        const temp_default = this.default_values['l'+i]? this.default_values['l'+i]: null;
        if(temp_default != null) {
          const temp_default_id = this.get_class_id_level(temp_default, i);
          this.classification_payload.get('classification_l'+i).setValue(temp_default_id)
          this.populate_next_dropdown({"target" :{"value": temp_default_id}}, i+1)
        }
      }
    }
  }

  private set_defaults_value() {

    //  setting defaults
    let temp_config_keys = Object.keys(mainConfig);
    var config_keys = temp_config_keys.reduce((obj, item) => (obj[item] = false, obj) ,{});
    for(let key in this.config) {
      config_keys[key] = true
    }
    for(let key in config_keys) {
      if(config_keys[key] == false) {
        this.config[key] = mainConfig[key]
      }
    }
  }

  private add_validation() {
    const end = this.allowed_classfication.end? this.allowed_classfication.end: this.allowed_classification_end;
    for(let i = this.allowed_classfication.start; i<=end;i++){
      if(this.mandatory){
        this.classification_payload.get('classification_l'+i).setValidators([Validators.required])
        this.classification_payload.get('classification_l'+i).updateValueAndValidity()
      }
      // allowed levels
      this.allowed_levels['l'+i] = true; 
    }
  }

  protected populate_next_dropdown(event,level){
    var sel_id =this.classification_root;
    if(event.target != undefined){
      sel_id = event.target.value;
    } else {
      sel_id = this.classification_root;
    }
    var child_ids = [];
    switch(level) {
      case 1:
      case 2:
      case 3:
      case 4:
      // case 5:
        const end = this.allowed_classfication.end? this.allowed_classfication.end: this.allowed_classification_end;
        for(i=level; i<=end; i++){
          this.class_dropdown['level_'+i] = [];
          this.classification_payload.get('classification_l'+i).setValue("");
        }
        child_ids = this.classification_heirarchy[sel_id];
        for(var i=0; i<this.classification_data.length; i++){
          if($.inArray(this.classification_data[i]['id'], child_ids) !== -1){
            this.class_dropdown['level_'+level].push(this.classification_data[i]);
          }
        }
        if(this.class_dropdown['level_'+level].length == 1){
          // this.populate_next_dropdown(level+1);
          var first_elem_id = this.class_dropdown['level_'+level][0]['id'];
          this.classification_payload.get('classification_l'+level).setValue(first_elem_id);
          // this.case_payload.get('classification_l'+level).setValue(this.class_dropdown['level_'+level][0]['id']);
          this.populate_next_dropdown({"target": {"value": first_elem_id}}, level+1);
        }
        break;
    }
  }

  public clear_form() {
    this.submitted_classification_payload = false;
    this.classification_payload.patchValue({
      'classification_l1':null,
      'classification_l2':null,
      'classification_l3':null,
      'classification_l4':null,
      'classification_l5':null,
    })
    this.set_default_value_of_classification();
  }

  public reset_dropdowns() {
    const end = this.allowed_classfication.end? this.allowed_classfication.end: this.allowed_classification_end;
    for(let i=this.allowed_classfication.start+1; i<=end; i++){
      this.class_dropdown['level_'+i] = [];
    }
  }

  public toggle_form(bool) {
    if(bool) {
      this.classification_payload.enable()
    } else {
      this.classification_payload.disable()
    }
  }

  public validate_task_classification_payload() {
    this.submitted_classification_payload = true;
    if (this.classification_payload.status == 'VALID' || this.classification_payload.status == 'DISABLED') {
      return true;
    } else {
      return false;
    }
  }

  public get_payload_ids() {
    return this.classification_payload.value;
  }

  public get_payload_values() {
    const data = this.classification_payload.value
    console.log(data);
    const valued_data = this.get_classfication_values(data)
    return valued_data
  }

  public get_payload_id_values() {
    const data = this.classification_payload.value
    const valued_data = this.get_classfication_values(data)
    const combined_data = {...data,...valued_data}
    return combined_data
  }

  public populate_data_by_id(data) {
    const old_data = this.classification_payload.getRawValue()
    if(!this.same_values(old_data, data)) {
      const end = this.allowed_classfication.end? this.allowed_classfication.end: this.allowed_classification_end;
      for(let i = this.allowed_classfication.start; i<=end;i++){
        this.populate_next_dropdown({"target" :{"value": data['classification_l'+i]}}, i+1);
      }
      this.classification_payload.patchValue(data);
    }

  }

  private same_values(old_data, new_data) {
    const end = this.allowed_classfication.end? this.allowed_classfication.end: this.allowed_classification_end;
    for(let i = this.allowed_classfication.start; i<=end;i++){
      if(old_data['classification_l'+i] != new_data['classification_l'+i]) {
        return false;
      }
    }
    return true
  }

  public populate_data_by_values(data) {
    const data_with_id = {};
    const end = this.allowed_classfication.end? this.allowed_classfication.end: this.allowed_classification_end;
    for(let i = this.allowed_classfication.start; i<=end;i++){
      const cl_id = this.get_class_id_level(data['classification_l'+i], i);
      data_with_id['classification_l'+i] = cl_id
      this.populate_next_dropdown({"target" :{"value": cl_id}}, i+1);
    }
    this.classification_payload.patchValue(data_with_id)
  }

  get_class_id_level(class_name, level) {
    var dd = this.class_dropdown['level_'+level];
    for(var i=0; i<dd.length; i++){
      if(class_name == dd[i]['value_name']){
        // console.log(dd[i]['value_name']);
        return dd[i]['id'];
      }
    }
  }
  
  get_classfication_values(payload) {
    const temp_obj = {
      classification_l1_value: '',
      classification_l2_value: '',
      classification_l3_value: '',
      classification_l4_value: '',
      classification_l5_value: '',
    }
    const end = this.allowed_classfication.end? this.allowed_classfication.end: this.allowed_classification_end;
    for(var i=1; i<=end; i++){
      for(var j=0; j<this.classification_data.length; j++){
        var class_id = parseInt(payload['classification_l'+i]);
        if(class_id == this.classification_data[j]['id']){
          temp_obj['classification_l'+i + '_value'] = (this.classification_data[j]['value_name'])
          break;
        }
      }
    }
    return temp_obj;
  }
  

}


// default values else error