import { Injectable } from '@angular/core';
import * as internet_connection from 'is-online';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root'
})

export class check_internet {
    constructor(
        private _env: EnvService,
    ) {
        if (this._env.check_net_connectivity) {
            this.subscribe_net_observers();
        }
    }
    net_interval = null;
    is_net_on = new BehaviorSubject(true);
    sip_is_net_on = new BehaviorSubject(true);
    jssip_net_ping_timer = 0; // will get updated value from telephony_config.
    net_conn_observer = new Observable<any>(observer => {
        setInterval(() => {
            observer.next(this.check_net_connectivity());
        }, this._env.net_ping_timer);
    });

    subscribe_net_observers() {
        this.net_conn_observer.subscribe();
        this.is_net_on.subscribe(res => {
            // console.log(res)
            if (res) {
                clearInterval(this.net_interval);
                this.net_interval = 0;
                this.sip_is_net_on.next(res);
            } else {
                this.net_interval = setInterval(() => {
                    this.sip_is_net_on.next(res);
                }, this.jssip_net_ping_timer);
            }
            // console.log(this.sip_is_net_on);
        })
    }

    check_net_connectivity() {
        (async () => {
            this.is_net_on.next(await internet_connection());
        })();
    }

    async ret_net_connection() {
        const is_net = await internet_connection().then((value) => { return value });
        return is_net
    }
}
