import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeInMinutes'
})
export class TimeInMinutesPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let hrs = 0
    let min= 0
    let result = ""
    if(value>=60){
      min = value%60
      hrs =  Math.floor(value/60)
      result = hrs + " hours " + min + " minutes"
    }else {
      min = value
      hrs = 0
      result = value + " minutes"
    }
    console.log(result)
    return result
  }

}
