import { Component, OnInit } from '@angular/core';
//import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear = null;

  constructor(
    //public navService: NavigationService,
  ) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

}
