import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/dce-service/env.service';

@Component({
  selector: 'app-instance-type',
  templateUrl: './instance-type.component.html',
  styleUrls: ['./instance-type.component.scss']
})
export class InstanceTypeComponent implements OnInit {

  instance_project_type = ""

  constructor(private _env: EnvService) { }

  ngOnInit() {
    this.instance_project_type = this._env.instance_type
  }

}
