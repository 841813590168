import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminLayoutSidebarLargeComponent } from './admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { HeaderSidebarLargeComponent } from './admin-layout-sidebar-large/header-sidebar-large/header-sidebar-large.component';
import { AdminLayoutSidebarCompactComponent } from './admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { SidebarLargeComponent } from './admin-layout-sidebar-large/sidebar-large/sidebar-large.component';
import { SidebarCompactComponent } from './admin-layout-sidebar-compact/sidebar-compact/sidebar-compact.component';
import { HeaderSidebarCompactComponent } from './admin-layout-sidebar-compact/header-sidebar-compact/header-sidebar-compact.component';
import { FooterComponent } from './footer/footer.component';

import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SharedDirectivesModule } from 'src/app/dce-directive/shared-directives.module';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { SearchModule } from '../search/search.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { FooterComponent } from '../footer/footer.component';
// import { CustomizerComponent } from '../customizer/customizer.component';
//import { DceSharedModule } from 'src/app/dce-shared/dce-shared.module';
// import { KnowledgeManagementSearchModule } from 'src/app/dce-views/knowledge-management-search/knowledge-management-search.module';
// import { JssipModule } from 'src/app/dce-views/jssip/jssip.module';
// import { WorkflowActionsModule } from 'src/app/dce-views/workflow-actions/workflow-actions.module';

const components = [
    HeaderSidebarCompactComponent,
    HeaderSidebarLargeComponent,
    SidebarLargeComponent,
    SidebarCompactComponent,
    AdminLayoutSidebarLargeComponent,
    AdminLayoutSidebarCompactComponent,
    AuthLayoutComponent,
    BlankLayoutComponent,
    FooterComponent,
    // CustomizerComponent,
];

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    CommonModule,
    FontAwesomeModule,
    // ToastrModule
    //DceSharedModule,
    NgbModule,
    //SearchModule,
    PerfectScrollbarModule,
    //KnowledgeManagementSearchModule,
    // JssipModule,
    // WorkflowActionsModule
  ],
  declarations: components,
  exports: components
})
export class LayoutsModule { }
