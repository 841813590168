import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { InitService } from './init.service';
import { EnvService } from './env.service';
import { Observable, of } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from './toastr-utility.service';
import { tap, catchError, finalize } from "rxjs/operators";
import { Router } from '@angular/router';
import { SharedService } from './shared.service';


@Injectable({
	providedIn: 'root'
})
export class TokenInterceptService implements HttpInterceptor {

	service_count = 0; // counter for service

	constructor(
		private _init: InitService,
		private _env: EnvService,
		private sharedService: SharedService,
		private _toastr: ToastrService,
		private _toastrUtility: ToastrUtilityService,
		private _route: Router
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const info_to_pass = {
			url: this._route.url,
			IP: localStorage.getItem('user_ip') == 'null' ? null : localStorage.getItem('user_ip'),
			device: localStorage.getItem('device') == 'null' ? null : localStorage.getItem('device')
		}
		var temp = req.url.split('/');
		var service = "/" + temp.slice(3).join("/");
		service = service.split('?')[0]; // to extract url of get-reqs eliminating query-params.

		if (this._env.cache_service.includes(service)) {
			let body = this.sharedService.get_cached_data(service, req.body)
			if (body.status) {
				return of(new HttpResponse(
					{ status: 200, body: body['data'] }
				));
			}
		}
		if (!this._env.skip_spinner_for_services.includes(service) && 0) {
			this.service_count++; // increament count for every http-req service-calls intercepted
			this.sharedService.spinnerVisibility(true);
			console.log('total service count', this.service_count);
		}

		var token;
		for (var i = 0; i < this._env.services_to_skip.length; i++) {
			if (this._env.services_to_skip.includes(service)) {	//	skip token addition to service
				token = null;
			} else {
				token = this._init.get_auth_token();
			}
		}

		if (req.method == 'GET') {
			if (token && (this._env.get_services_with_token.includes(service))) {
				let authReq = req.clone({
					url: req.url,
					headers: req.headers.append('Authorization', 'Bearer ' + token),
					withCredentials: true
				});
				if (this.sharedService.resetCache && this._env.force_cache_purge_services.includes(service)) {
					authReq = req.clone({
						url: req.url,
						headers: req.headers.append('Authorization', 'Bearer ' + token).append('Cache-Purge', "true"),
						withCredentials: true
					});
				}
				return next.handle(authReq).pipe(
					catchError((err: any) => {
						console.log(err.error)
						if (err instanceof HttpErrorResponse) {
							this._toastr.error('', 'Some Internal Error Occurred', this._toastrUtility.basic_configuration);
						}
						return of(err);
					})

				)
			} else {
				return next.handle(req);
			}
		} else if (req.method == 'POST') {
			if (token) {
				const authReq = req.clone({
					headers: req.headers.append('Authorization', 'Bearer ' + token)  //, 'role': 'role_from_local', 'user_id': 'user_id_from_local'
						.append('_info', JSON.stringify(info_to_pass)),
					body: req.body,
					withCredentials: true
				});
				return next.handle(authReq).pipe(
					tap((event: HttpEvent<any>) => {
						if (event instanceof HttpResponse && event.body.errCode != undefined) {
							if (event.body.errCode != 0) {
								this._toastr.error('', event.body.msg, this._toastrUtility.basic_configuration);
								if (event.body.errCode == 96) {
									localStorage.removeItem('token');
									location.reload(true);
								}
							} else if (this._env.cache_service.includes(service)) {
								this.sharedService.set_cached_data(service, req.body, event.body)
							} else if (this._env.update_cache_services.includes(service)) {
								this.sharedService.get_classifications().subscribe(res => {
								})
							}
						}
					}),
					catchError((err: any) => {
						if (err instanceof HttpErrorResponse) {
							this._toastr.error('', 'Some Internal Error Occurred', this._toastrUtility.basic_configuration);
						}
						return of(err);
					}),
					finalize(() => {
						if (!this._env.skip_spinner_for_services.includes(service) && 0) {
							this.service_count--; // decreament when service is completed (success/failed both handled)
							if (this.service_count === 0) {
								this.sharedService.spinnerVisibility(false);
							}
						}
					}),
				);

			} else {

				if (token == null && !this._env.services_to_skip.includes(service)) {
					this._toastr.error('', "You are not signed in", this._toastrUtility.basic_configuration);
					// this._route.navigate(['/sessions/signin'])
					location.reload(true);
				}
				return next.handle(req).pipe(
					tap((event: HttpEvent<any>) => {
						if (event instanceof HttpResponse && event.body.errCode != undefined && event.body.errCode != 0) {
							this._toastr.error('', event.body.msg, this._toastrUtility.basic_configuration);
						}
					}),
					catchError((err: any) => {
						if (err instanceof HttpErrorResponse) {
							this._toastr.error('', 'Some Internal Error Occurred', this._toastrUtility.basic_configuration);
						}
						return of(err);
					}),
					finalize(() => {
						if (!this._env.skip_spinner_for_services.includes(service) && 0) {
							this.service_count--; // decreament when service is completed (success/failed both handled)
							if (this.service_count === 0) {
								this.sharedService.spinnerVisibility(false);
								console.log('present service count', this.service_count);
							}
						}
					}),
				);
			}
		} else {
			return next.handle(req).pipe(
				catchError((err: any) => {
					if (err instanceof HttpErrorResponse) {
						this._toastr.error('', 'Some Internal Error Occurred', this._toastrUtility.basic_configuration);
					}
					return of(err);
				}),
				finalize(() => {
					if (!this._env.skip_spinner_for_services.includes(service) && 0) {
						this.service_count--; // decreament when service is completed (success/failed both handled)
						if (this.service_count === 0) {
							this.sharedService.spinnerVisibility(false);
						}
					}
				}),
			);
		}

	}

}
