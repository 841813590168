import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EnvService {

  public web_page_title = 'DCE Search';
  public service_host = 'https://ngatestazbe.search.asi.co.za';
  //public service_port = 4002;
  public service_url  = this.service_host;
  //public service_url  = this.service_host+':'+this.service_port;

  public raptor_db_host = 'ngadshbtestdb.asi.co.za';
  //public raptor_db_port = 5655;
  public raptor_wh_host = 'ngadshbtestwh.asi.co.za';
  //public raptor_wh_port = 5656;
  public raptor_db_service_url = 'https://' + this.raptor_db_host;
  public raptor_wh_service_url = 'https://' + this.raptor_wh_host;

  public load_batch_size = 1000;

  public telephony_conf = {
    conn_retry_limit: 300000, // in ms (5 min)
    conn_retry_attempt_interval: 15000, // in ms (15sec)
    err_log_enabled: true,
    codec_log_enabled: true,
    get_qm_stats_fire_after: 5000
  };
  public blacklist_file = ['exe', 'sh']
  public build_version = '1.2.39';
  public show_file_in_browser = ['pdf', 'txt', 'png'];
  public job_grade_exceptions = ['Manager', 'Senior Manager'];
  public notification_interval = 3600000;
  public get_services_with_token = ['/data/classifications','/campaign/version/get','/campaign/version/target/download_invalid','/campaign/version/target/get_counts','/interaction/individual/get_all','/interaction/biz_org/get_all','/interaction/documents/get_all','/campaign/version/get_status','/meta/alloc/tenant/notification/get_all','/meta/alloc/tenant/content_base/get_all','/meta/alloc/tenant/dispatch_definition/get_all','/meta/alloc/tenant/email_content/get_all','/meta/alloc/tenant/user_profile/get_all','/bizorg/tenant/get_all','/biz_org/fsp/get_100','/classification/fetch/flatlist'];
  public cvt_number_of_records = 10; // cvt = campaign_version_target
  public paginated_search = {
    'case' : {
      search: true,
      my_case_number_of_records: 50, // by default it will be used
      customer_case_number_of_records: 1000,
      search_type: 'local' // 'local' | 'service'
    },
    'task' : {
      search: true,
      number_of_records: 50
    }
  };
  public net_ping_timer = 10000;
  public check_net_connectivity = false;

  public skip_spinner_for_services = [
  ];
  public note_reply_level = 4;
  public user_signature_type = "profile"  // template/profile


  public dropdown_list = {
  }
  public telephony_cmp_conf = {
    ver_time_check_interval: 30000 // 1 minute
  }

  public services_to_skip = ['/login', '/register', '/send_forgot_password_email', '/forgot_password', '/get_feedback_form_details', '/save_feedback', '/check_password_already_used', '/lg_query/get_query_by_query_id', '/lg_composite_widget/get_widget_by_id', '/lg_report/get_report_by_id', '/lg_query/get_query_by_query_id', '/lg_composite_widget/get_widget_by_id', '/lg_report/get_report_by_id', '/lg_vinsights/get_vinsights_by_id', '/queuemetrics', '/queuemetrics/QmAgentQueues/qm_jsonsvc_do_pbxactions.do', '/queuemetrics/QmAgentPCodes/jsonStatsApi.do', '/queuemetrics/qm_jsonsvc_do_pbxactions.do','/check_case_feedback_submitted'];
  public cache_service = ['/get_case_classification_dropdowns', '/get_task_classification_dropdown', '/get_all_workflow_actions', '/get_classification_value_data_dropdown', '/get_signature_from_user_id', '/get_all_users_for_filters', '/get_all_case_status','/workflow/user/workgroups/object_status']

  public update_cache_services = ['/create_mobile_classification_mapping', '/update_mobile_classification_mapped_data', '/remove_classification', '/create_classification', '/add_classification_value', '/update_classification_value', '/add_priority_classification', '/remove_priority_classification', '/update_priority_classification', '/update_classification_extension', '/update_priority_severity_deadline', '/classification_extension/checkbox','/move_classification_value']
  public force_cache_purge_services = ['/data/classifications']
  public lg_wh_services = ['/whapi/getCompWidget', '/whapi/getPivotReport', '/whapi/getDrillReport', '/whapi/queryTest', '/whapi/processQueryFilters', '/api/lg_dashboards', '/whapi/create_report', '/whapi/download_file', '/whapi/create_chart_report', '/api/lg_reports', '/api/lg_query', '/lg_query/get_query_by_query_id', '/api/lg_report_dashboard', '/api/lg_vinsights', '/lg_composite_widget/get_widget_by_id', '/lg_report/get_report_by_id', '/lg_vinsights/get_vinsights_by_id', '/whapi/getVIParam', '/whapi/filterFromQuery', '/whapi/send_mail_from_vi', '/api/lg_dashboards', '/api/lg_composite_widgets', '/whapi/queryTestById']
  public GET_services_to_skip = []

  public project_variables = {
    'org_name': 'Organisation',
    'org_name_s': 'Organisations',
    'emp_name': 'Customer',
    'show_certification': true,
    'stock_code_label': '',
    'cost_price': 'Cost',
    'wg_user_name': 'User',
    'wg_user_name_s': 'Users'
  };

  public is_prod = true;

  public project_dropdown_list = {
    'hospitals': 214,
    'coverage': 215,
    'day_to_day_cover': 216,
    'day_to_day_extent': 217,
    'adult_dependent': 218,
    'child_dependent': 219,
  };

  public gems_dropdown_list = {
    'gems_hospital_cover': 232,
    'gems_chronic_conditions_cover': 233,
    'gems_in_hospital_benefit': 234,
    'gems_out_hospital_benefit': 235
  };

  public docchecklist_config = {
    single_file_size: 1048576 * 10,
    max_file_size: 1048576 * 25,
  };

  public task_percent_color_map = {
    '0-50': "#000000de",
    '51-80': "#F27E17",
    '81-100': "#ff0000"
  };

  public task_classification_default_values = {
    l1: 'NA'
  };

  public default_time = {
    'start_time': '09:00',
    'end_time' : '18:00'
  };

  public poller_company_name_for_case = 'Unidentified-Customer';
  public date_format = 'dd MMM yyyy HH:mm'
  public send_doc_with_notification = {
    'case':true,
    'task':false
  }

  public completed_status_for_voice = ['Completed'];

  public mobile_case_classification_level = 3
  public case_classification_level = 4
  public case_classification_config = {
    l1: { 'name': 'Service', 'class': 'col-6' },
    l2: { 'name': 'Category', 'class': 'col-6' },
    l3: { 'name': 'Sub-category', 'class': 'col-6' },
    l4: { 'name': 'Issues', 'class': 'col-6' }
  }
  public case_classification_default_values = {
    'classification_l1':'',
    'classification_l2':'',
    'classification_l3':'',
    'classification_l4':''
  };

  public instance_type = "DCE-SEARCH TEST";

  public image_file_extension = ['jpg','png','jpeg', 'tif', 'tiff', 'bmp', 'gif', 'eps'];

  public use_local_storage = false;

  constructor() { }

}

export const web_page_title='ASI CRM';
export const homepage_title = 'ASI CRM';


