import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type confirmType = 'success' | 'cancel';

@Component({
  selector: 'app-in-place-confirmation',
  templateUrl: './in-place-confirmation.component.html',
  styleUrls: ['./in-place-confirmation.component.scss']
})
export class InPlaceConfirmationComponent implements OnInit {

  @Input() confirmation?:boolean = true;
  @Input() confirmation_text?:string = 'Are you sure you want to remove this document ?';
  @Input() confirmation_success?:string = 'Yes';
  @Input() confirmation_cancel?:string = 'No'
  @Input() btn_success_class?:string = 'btn btn-sm btn-confirm btn-danger btn-rounded mr-2';
  @Input() btn_cancel_class?:string = 'btn btn-sm btn-confirm btn-secondary btn-rounded';
  @Input() unique_id =  '';

  @Output() confirmation_event: EventEmitter<ConfirmationEvent> = new EventEmitter<ConfirmationEvent>();

  constructor() { }

  ngOnInit() {
  }

  success() {
    this.confirmation_event.emit({'type': 'success'});
  }

  cancel() {
    this.confirmation_event.emit({'type': 'cancel'});
  }

}

interface ConfirmationEvent {
  type: confirmType
}