import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from './env.service';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  authenticated = true;

  private login_url = `${this.env.service_url}/login`;

  constructor(
		private env: EnvService,
    private http: HttpClient,
    private _router: Router
    ) {
      this.checkAuth();
    }

  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
  }

  getuser() {
    return of({});
  }

  signin(credentials) {
    this.authenticated = true;
    console.log(credentials);
    // this.store.setItem("demo_login_status", true);
    return of({}).pipe(delay(1500));
  }
  signout() {
    this.authenticated = false;
    localStorage.removeItem('token');
    localStorage.removeItem('wh_token');
    // this.store.setItem("demo_login_status", false);
    this._router.navigateByUrl("/sessions/signin");
  }

  login_service(payload) {
    return this.http.post<any>(this.login_url, payload);
  }
  
}
