import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { httpInterceptorProviders } from './dce-service/index';
// import { CaseManagementSetupComponent } from './dce-views/case-management-setup/case-management-setup.component';
// import { ClassificationMapComponent } from './dce-views/case-management-setup/classification-map/classification-map.component';
// import { TimeMapComponent } from './dce-views/case-management-setup/time-map/time-map.component';
// import { CaseEmailComponent } from './dce-views/case-management-setup/case-email/case-email.component';
// import { AngularSplitModule } from 'angular-split';
import { Resolver } from './resolver';
import { DceSharedModule } from './dce-shared/dce-shared.module';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    // CaseManagementSetupComponent,
    // ClassificationMapComponent,
    // TimeMapComponent,
    // CaseEmailComponent
    // ClassificationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    // AngularSplitModule.forRoot(),
    AppRoutingModule,
    DceSharedModule,
    ToastrModule.forRoot()
  ],
  providers: [
    Resolver, 
    httpInterceptorProviders, 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// For testing