import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export abstract class ProjectService {

  constructor(
    protected http: HttpClient,
    protected env: EnvService
  ) { }

  private foo_url = `${this.env.service_url}/foo`;
  foo() {
    return this.http.post<any>(this.foo_url, {});
  }
// /v1/get_filters_from_employee_id_frontend_req
  private get_scheme_data_from_ids_url = `${this.env.service_url}/moses/v1/get_scheme_data_from_ids`;
	get_scheme_data_from_ids(payload) {
		return this.http.post<any>( this.get_scheme_data_from_ids_url, payload);
  }
  
  private export_scheme_comparison_url = `${this.env.service_url}/moses/v1/export_scheme_comparison`;
	export_scheme_comparison(payload) {
		return this.http.post<any>( this.export_scheme_comparison_url, payload);
	}
	
  // FNA Module 
		public search_employee_fm_url = `${this.env.service_url}/moses/v1/search_employee_fm`;
		search_employee_fm(payload){
			return this.http.post<any>( this.search_employee_fm_url, payload);
    }
    
    private get_filters_from_employee_id_frontend_req_url = `${this.env.service_url}/moses/v1/get_filters_from_employee_id_frontend_req`;
    get_filters_from_employee_id_frontend_req(payload){
      return this.http.post<any>( this.get_filters_from_employee_id_frontend_req_url, payload);
    }
    private filter_schemes_url = `${this.env.service_url}/moses/v1/filter_schemes`;
		filter_schemes(payload){
			return this.http.post<any>( this.filter_schemes_url, payload);
    }

    private get_all_gap_data_url = `${this.env.service_url}/moses/v1/get_all_gap_data`;
		get_all_gap_data(payload){
			return this.http.post<any>( this.get_all_gap_data_url, payload);
    }

    private get_gap_data_from_gap_ids_url = `${this.env.service_url}/moses/v1/get_gap_data_from_gap_ids`;
		get_gap_data_from_gap_ids(payload){
			return this.http.post<any>( this.get_gap_data_from_gap_ids_url, payload);
    }

    private get_all_advice_documents_url = `${this.env.service_url}/moses/v1/get_all_advice_documents`;
		get_all_advice_documents(payload){
			return this.http.post<any>( this.get_all_advice_documents_url, payload);
    }

    private get_filters_from_employee_id_url = `${this.env.service_url}/moses/v1/get_filters_from_employee_id`;
		get_filters_from_employee_id(payload){
			return this.http.post<any>( this.get_filters_from_employee_id_url, payload);
    }

    private create_advice_url = `${this.env.service_url}/moses/v1/create_advice`;
		create_advice(payload){
			return this.http.post<any>( this.create_advice_url, payload);
    }

    private save_advice_url = `${this.env.service_url}/moses/v1/save_advice`;
		save_advice(payload){
			return this.http.post<any>( this.save_advice_url, payload);
    }

    private update_advice_rec_url = `${this.env.service_url}/moses/v1/update_advice_rec`;
		update_advice_rec(payload){
			return this.http.post<any>( this.update_advice_rec_url, payload);
    }

    private update_advice_needs_url = `${this.env.service_url}/moses/v1/update_advice_needs`;
		update_advice_needs(payload){
			return this.http.post<any>( this.update_advice_needs_url, payload);
    }

    private export_advice_url = `${this.env.service_url}/moses/v1/export_advice`;
		export_advice(payload){
			return this.http.post<any>( this.export_advice_url, payload);
    }

    private export_gap_url = `${this.env.service_url}/moses/v1/export_gap`;
        export_gap(payload){
            return this.http.post<any>( this.export_gap_url, payload);
    }

    private download_advice_report_url = `${this.env.service_url}/moses/v1/download_advice_report`;
		download_advice_report(payload){
			return this.http.post<any>( this.download_advice_report_url, payload);
    }

    private send_scheme_comparison_mail_url = `${this.env.service_url}/moses/v1/send_scheme_comparison_mail`;
		send_scheme_comparison_mail(payload){
			return this.http.post<any>( this.send_scheme_comparison_mail_url, payload);
    }

    private send_gap_comparison_mail_url = `${this.env.service_url}/moses/v1/send_gap_comparison_mail`;
		send_gap_comparison_mail(payload){
			return this.http.post<any>( this.send_gap_comparison_mail_url, payload);
    }

    private email_advice_documents_url = `${this.env.service_url}/moses/v1/email_advice_documents`;
		email_advice_documents(payload){
			return this.http.post<any>( this.email_advice_documents_url, payload);
    }

    private resend_email_advice_documents_url = `${this.env.service_url}/moses/v1/resend_email_advice_documents`;
		resend_email_advice_documents(payload){
			return this.http.post<any>( this.resend_email_advice_documents_url, payload);
    }

    private remove_advice_documents_url = `${this.env.service_url}/moses/v1/remove_advice_documents`;
		remove_advice_documents(payload){
			return this.http.post<any>( this.remove_advice_documents_url, payload);
    }

    private get_premium_from_scheme_id_url = `${this.env.service_url}/moses/v1/get_premium_from_scheme_id`;
		get_premium_from_scheme_id(payload){
			return this.http.post<any>( this.get_premium_from_scheme_id_url, payload);
    }

   private get_chronic_conditions_list_url = `${this.env.service_url}/moses/v1/get_chronic_conditions_list`;
		get_chronic_conditions_list(payload){
			return this.http.post<any>( this.get_chronic_conditions_list_url, payload);
    }

    private create_insurance_needs_url = `${this.env.service_url}/moses/v1/create_insurance_needs`;
		create_insurance_needs(payload){
			return this.http.post<any>( this.create_insurance_needs_url, payload);
    }

    private update_advice_filters_url = `${this.env.service_url}/moses/v1/update_advice_filters`;
		update_advice_filters(payload){
			return this.http.post<any>( this.update_advice_filters_url, payload);
    }

    private get_contact_details_from_employee_id_url = `${this.env.service_url}/moses/v1/get_contact_details_from_employee_id`;
		get_contact_details_from_employee_id(payload){
			return this.http.post<any>( this.get_contact_details_from_employee_id_url, payload);
    }

    private get_employee_dependents_url = `${this.env.service_url}/moses/v1/get_employee_dependents`;
		get_employee_dependents(payload){
			return this.http.post<any>( this.get_employee_dependents_url, payload);
    }

    private get_potential_member_dependents_url = `${this.env.service_url}/moses/v1/get_potential_member_dependents`;
		get_potential_member_dependents(payload){
			return this.http.post<any>( this.get_potential_member_dependents_url, payload);
    }

    private close_advice_url = `${this.env.service_url}/moses/v1/close_advice`;
        close_advice(payload){
            return this.http.post<any>( this.close_advice_url, payload);
    }

    // Product Configuration
		private get_all_product_houses_url = `${this.env.service_url}/moses/v1/get_product_houses`;
		get_all_product_houses(payload) {
			return this.http.post<any>( this.get_all_product_houses_url, payload);
		}
		private get_product_list_url = `${this.env.service_url}/moses/v1/get_products_from_product_house_id`;
		get_product_list(payload) {
			return this.http.post<any>( this.get_product_list_url, payload);
		}
		private get_scheme_details_url = `${this.env.service_url}/moses/v1/get_scheme_details_from_product_id`;
		get_scheme_details(payload) {
			return this.http.post<any>( this.get_scheme_details_url, payload);
		}
		private change_scheme_status_url = `${this.env.service_url}/moses/v1/change_scheme_status`;
		change_scheme_status(payload) {
			return this.http.post<any>( this.change_scheme_status_url, payload);
		}

}
